* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

:root {
    --col-primary: #254960;
    --col-secondary: #3f647d;
    --col-body: #738da6;
    --col-label: #e2f1f7;
    --col-dark: #000;
    --col-br-light: #e9f0fb;
    --col-br-dark: #e2f1f7;
    --col-white: #ffffff;
    --col-E4FBFA: #e4fbfa;
    --col-tra: transparent;
    --col-table-head: #f4fafd;
    --col-4B5564: #4b5564;
    --col-C5D2E7: #c5d2e7;
    --col-E9E9E9: #e9e9e9;
    --col-E02B1D: #e02b1d;
    --col-FFF1F0: #fff1f0;
    --col-5DEADE: #5deade;
    --col-FFB240: #ffb240;
    --col-FFF8EC: #fff8ec;
    --col-32C997: #32c997;
    --col-F1FBF8: #f1fbf8;
    --col-009262: #009262;
    --col-1145C8: #1145c8;
    --col-F0857D: #f0857d;
    --col-FF004D: #ff004d;
    --col-FFC670: #ffc670;
    --col-FDF5ED: #fdf5ed;
    --col-84DFC1: #84dfc1;
    --col-FF5A4F: #ff5a4f;
    --col-FFCCDB: #ffccdb;
    --col-EFFAFF: #effaff;
    --col-2D343F: #2d343f;
    --col-F8FDFF: #F8FDFF;
    --col-E9E5E5: #e9e5e5;
    --col-hov-c5fdfa: #c5fdfa;
    --col-hov-f9d9ba: #f9d9ba;
    --col-hov-f7d2cf: #f7d2cf;
    --col-btn-primary: #17b1a4;
    --col-btn-primary-hover: #128c82;
    --col-warning: #ffa826;
    --col-error: #e01507;
    --col-success: #1b6e53;
    --col-sidebar-text: #738da6;
    --font-family: 'Rubik', sans-serif;
    --font-10: 10px;
    --font-11: 11px;
    --f-w-400: 400;
    --font-12: 12px;
    --font-13: 13px;
    --font-14: 14px;
    --f-w-600: 600;
    --font-16: 16px;
    --f-w-500: 500;
    --font-18: 18px;
    --font-24: 24px;
    --font-32: 32px;
    --font-92-81: 92.81px;
    --shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    --hov-shadow: 0 4px 8px -0px rgba(57, 67, 87, .3);
    --col-select-white: #fff;
    --col-multi-select-option: hsl(0, 0%, 90%);
}

[data-theme='dark'] {
    --col-white: #181A1B !important;
    --col-table-head: #1C1E1F !important;
    --col-primary: #97bed7 !important;
    --col-secondary: #86aac2 !important;
    --col-body: #738da6 !important;
    --col-dark: #fff;
    --col-label: #102e39 !important;
    --col-br-light: #181a1b !important;
    --col-br-dark: #102e39 !important;
    --col-E4FBFA: #093D3B !important;
    --col-tra: rgba(0, 0, 0, 0) !important;
    --col-table-head: #1c1e1f !important;
    --col-4B5564: #b1aaa0 !important;
    --col-C5D2E7: #b2c9df !important;
    --col-E9E9E9: #363b3d !important;
    --col-E02B1D: #e02b1d !important;
    --col-FFF1F0: #3c0400 !important;
    --col-5DEADE: #128e86 !important;
    --col-FFB240: #9f5f00 !important;
    --col-FFF8EC: #3e2700 !important;
    --col-32C997: #24906c !important;
    --col-F1FBF8: #0e3028 !important;
    --col-009262: #00754e !important;
    --col-1145C8: #0e37a0 !important;
    --col-F0857D: #7c160e !important;
    --col-FF004D: #ff1a5f !important;
    --col-FFC670: #895200 !important;
    --col-FDF5ED: #3b1d00 !important;
    --col-84DFC1: #1f7659 !important;
    --col-FF5A4F: #9d0a00 !important;
    --col-FFCCDB: #520018 !important;
    --col-EFFAFF: #002A3D !important;
    --col-2D343F: #c6c1b9 !important;
    --col-F8FDFF: #0A242E !important;
    --col-E9E5E5: #26292a !important;
    --col-hov-c5fdfa: #035451 !important;
    --col-hov-f9d9ba: #582f08 !important;
    --col-hov-f7d2cf: #49110c !important;
    --col-btn-primary: #128e83 !important;
    --col-btn-primary-hover: #0e7068 !important;
    --col-warning: #ffa826 !important;
    --col-error: #e01507 !important;
    --col-success: #1b6e53 !important;
    --col-sidebar-text: #d1cdc7 !important;
    --font-family: 'Rubik', sans-serif;
    --font-10: 10px;
    --font-11: 11px;
    --f-w-400: 400;
    --font-12: 12px;
    --font-13: 13px;
    --font-14: 14px;
    --f-w-600: 600;
    --font-16: 16px;
    --f-w-500: 500;
    --font-18: 18px;
    --font-24: 24px;
    --font-32: 32px;
    --font-92-81: 92.81px;
    --shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(24, 50, 95, 0.13) 0px 0px 1px 1px;
    --hov-shadow: 0 4px 8px -0px rgba(81, 87, 102, 0.3);
    --col-select-white: #222527 !important;
    --col-multi-select-option: hsl(0, 0%, 22%);

    .bg-white {
        --bs-bg-opacity: 1 !important;
        background-color: rgb(24, 26, 27) !important;
    }

    a {
        color: #d1cdc7 !important;
    }

    .modal-content {
        background-color: #181A1B !important;
        border: 1px solid #383d3f !important;

        .btn-close {
            border: 1px solid #383d3f !important;
            --bs-btn-close-bg: url(data: image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22%23f00%22%3E%3Cpath%20d%3D%22M.293.293a1%201%200%200%201%201.414%200L8%206.586%2014.293.293a1%201%200%201%201%201.414%201.414L9.414%208l6.293%206.293a1%201%200%200%201-1.414%201.414L8%209.414l-6.293%206.293a1%201%200%200%201-1.414-1.414L6.586%208%20.293%201.707a1%201%200%200%201%200-1.414z%22%2F%3E%3C%2Fsvg%3E) !important;
        }
    }

    .btn-close {
        border: 1px solid #383d3f !important;
        --bs-btn-close-bg: url(data: image/svg+xml, %3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22%23f00%22%3E%3Cpath%20d%3D%22M.293.293a1%201%200%200%201%201.414%200L8%206.586%2014.293.293a1%201%200%201%201%201.414%201.414L9.414%208l6.293%206.293a1%201%200%200%201-1.414%201.414L8%209.414l-6.293%206.293a1%201%200%200%201-1.414-1.414L6.586%208%20.293%201.707a1%201%200%200%201%200-1.414z%22%2F%3E%3C%2Fsvg%3E) !important;
    }

    .custom-btn {
        border: 1px solid #383d3f !important;
    }

    input,
    select,
    textarea,
    #select-btn {
        background-color: #222527 !important;
        color: #fff !important;
        border-color: #383d3f !important;
    }

    .dropdown-menu {
        background-color: #181A1B !important;
        border: 1px solid #383d3f !important;
    }

    .list-group {
        border: 1px solid #383d3f !important;

        .list-group-item {
            background-color: #181A1B !important;
            color: #d1cdc7 !important;
            border: 1px solid #383d3f !important;

        }
    }

    .customer-dashboard-view .top-right .grey-label {
        background-color: #413e3e !important;

        &:hover {
            background-color: #616161 !important;
        }
    }

    table {
        thead {

            th {
                background-color: var(--col-E4FBFA) !important;
                color: #d1cdc7 !important;
            }

            tr {
                background-color: var(--col-E4FBFA) !important;
                color: #d1cdc7 !important;
            }

        }

        tbody {
            tr {
                border-color: #383d3f !important;

                td {
                    background: var(--col-white) !important;
                    color: #d1cdc7 !important;
                }
            }

            tr:nth-child(even) {
                td {
                    background: var(--col-select-white) !important;
                    color: #d1cdc7 !important;
                }
            }
        }
    }

    .edit-customer-heading {
        border-color: #383d3f !important;
    }

    .border-bottom {
        border-color: #383d3f !important;
    }

    .border {
        border-color: #383d3f !important;
    }

    input:disabled,
    select:disabled,
    textarea:disabled {
        cursor: not-allowed !important;
        background-color: rgba(255, 255, 0, 0.07) !important;
    }
}

table {
    border-collapse: separate !important;
    border-spacing: 0px !important;

    tbody {
        tr {
            &:hover {
                background-color: var(--col-E4FBFA) !important;
            }
        }
    }
}

body {
    color: var(--col-primary) !important;
    background-color: var(--col-white) !important;
    font-weight: var(--f-w-400) !important;
    font-size: var(--font-14) !important;
    font-family: var(--font-family) !important;
    position: relative;
    width: 100dvw;
    height: 100dvh;
    overflow: hidden !important;
}

main {
    height: calc(100dvh - 72px);
    overflow: hidden !important;
}

main.isImpersonated {
    height: calc(100dvh - 96px);
}

.ssc-main-content {
    padding: 16px 16px !important;
}

.main-header {
    font-size: var(--font-18);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 0px;
}

.ssc-header-title {
    height: 36px;
}

.ssc-secondary-white-btn {
    padding: 10px 16px;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--col-btn-primary);
    border-radius: 4px;
    background-color: var(--col-white);
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-btn-primary);
    transition: all 0.3s ease-in-out;
}

.ssc-secondary-white-btn p {
    margin-bottom: 0px;
}

.ssc-secondary-white-btn:hover {
    background-color: var(--col-label);
    border: 1px solid var(--col-btn-primary) !important;
    color: var(--col-btn-primary) !important;
}

.ssc-primary-green-btn {
    background-color: var(--col-btn-primary);
    max-height: 40px;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-18);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    border: none;
    border-radius: 8px;
    color: var(--col-white);
    transition: all 0.3s ease-in-out;
}

.ssc-primary-green-btn:hover {
    background-color: var(--col-btn-primary-hover);
}

.ssc-primary-green-btn:disabled {
    background-color: var(--col-label);
    cursor: not-allowed;
}

.ssc-primary-green-btn-small {
    background-color: var(--col-btn-primary);
    max-height: 36px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    border: none;
    border-radius: 6px;
    color: var(--col-white);
    transition: all 0.3s ease-in-out;
}

.ssc-primary-green-btn-small:hover {
    background-color: var(--col-btn-primary-hover);
}

.ssc-primary-green-btn-small:disabled {
    background-color: var(--col-label);
    cursor: not-allowed;
}

.ssc-card-btn {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-btn-primary);
    transition: all 0.3s ease-in-out;
    gap: 8px;
    border: none;
    background-color: var(--col-white);
    display: flex;
    align-items: center;
}

.ssc-card-btn:hover {
    color: var(--col-btn-primary-hover);
}

.ssc-card-btn2 {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-btn-primary);
    transition: all 0.3s ease-in-out;
    gap: 8px;
    border: none;
    background-color: var(--col-white);
    display: flex;
    align-items: center;
}

.ssc-card-btn2:hover {
    color: var(--col-btn-primary-hover);
}

.ssc-search-box .search-box {
    position: relative;
    width: 100%;
    padding: 8px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    gap: 8px;
    background-color: var(--col-white);
}

.ssc-search-box .search-box .search-area {
    color: var(--col-body);
    line-height: 20px;
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    letter-spacing: 0.005em;
}

.ssc-search-box .search-box .search-area::placeholder {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-body);
}

.ssc-search-box .search-box .search-area:focus-visible,
.ssc-search-box .search-box .search-area:focus {
    outline: none !important;
}

.ssc-search-box .ssc-filters {
    gap: 8px;
}

.ssc-search-box .ssc-filters select {
    height: 36px;
    border: 1px solid var(--col-E9E9E9);
    padding: 0px 12px;
    border-radius: 4px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    background-color: var(--col-white);
}

.ssc-table-pagination-bar {
    height: 24px;
    max-height: 24px;
    color: var(--col-body);
}

.ssc-table-pagination-bar .left-number-set {
    display: flex;
    gap: 4px;
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    text-wrap: nowrap;
    margin-right: 8px;
}

.ssc-table-pagination-bar .right-number-set {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ssc-table-pagination-bar .right-number-set .number-set {
    text-wrap: nowrap;
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
}

.dot-set {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65px;
}

.dot-set .col-dot {
    border-radius: 100%;
    height: 10px;
    width: 10px;
    min-width: 10px;
}

.dot-set .red-dot {
    background-color: var(--col-error);
}

.dot-set .green-dot {
    background-color: var(--col-009262);
}

.dot-set .blue-dot {
    background-color: var(--col-1145C8);
}

.dot-set .yellow-dot {
    background-color: var(--col-warning);
}

.status-set {
    display: flex;
    align-items: center;
}

.status-set .status {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-12);
    font-weight: var(--f-w-400);
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    border-radius: 1000px;
    padding: 0px 8px;
    height: 20px;
}

.status-set .rejected-status {
    border: 1px solid var(--col-E02B1D);
    background-color: var(--col-FFF1F0);
}

.status-set .requested-status {
    border: 1px solid var(--col-5DEADE);
    background-color: var(--col-E4FBFA);
}

.status-set .pending-status {
    border: 1px solid var(--col-FFB240);
    background-color: var(--col-FFF8EC);
}

.status-set .approved-status {
    border: 1px solid var(--col-32C997);
    background-color: var(--col-F1FBF8);
}

.custom-date-box {
    border: 1px solid var(--col-E9E9E9);
    color: var(--col-secondary);
    height: 36px;
    padding: 0 12px;
    gap: 4px;
    border-radius: 4px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    cursor: pointer;
}

.select-box-new {
    border: 1px solid var(--col-E9E9E9);
    color: var(--col-secondary);
    height: 32px;
    padding: 0 12px;
    margin-right: 47px;
    border-radius: 4px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    background-color: var(--col-white) !important;
}

.mt-32 {
    margin-top: 32px;
}

.mb-32 {
    margin-bottom: 32px;
}

.modal.fade {
    background-color: rgba(0, 0, 0, 0.175) !important;
}

.btn.ssc-secondary-white-btn,
.ssc-secondary-white-btn.btn:first-child:active {
    color: var(--col-btn-primary) !important;
    background-color: var(--col-white) !important;
    border-color: var(--col-btn-primary) !important;
}

.ssc-secondary-white-btn.btn:hover {
    background-color: var(--col-label) !important;
    border: 1px solid var(--col-btn-primary) !important;
    color: var(--col-btn-primary) !important;
}

select:focus-visible {
    outline: none;
}

.ssc-primary-green-btn.unique-btn {
    border-radius: 4px;
    height: 36px;
    width: 50px;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
}

.hov-effect-boardcast {
    padding: 5px;
    width: 40px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.hov-effect-boardcast:hover {
    background-color: var(--col-E4FBFA);
}

.dot-sentence {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.error-page-box1 h2 {
    font-size: var(--font-92-81);
    font-weight: var(--f-w-600);
    line-height: 1;
    letter-spacing: 0.0025em;
    color: var(--col-primary);
    margin: 48px 0;
}

.error-page-box1 button {
    border-radius: 8px;
}

.error-page-box2 h2 {
    font-size: var(--font-92-81);
    font-weight: var(--f-w-600);
    line-height: 1;
    letter-spacing: 0.0025em;
    color: var(--col-primary);
    margin-top: 32px;
    margin-bottom: 25px;
}

.error-page-box2 p {
    margin-bottom: 48px;
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-secondary);
}

.error-page-box2 button {
    border-radius: 8px;
}

.priority-box-set {
    gap: 16px;
}

.priority-box-set .box-container-set {
    border: 1px solid var(--col-br-dark);
    border-radius: 4px;
    height: 40px;
    padding: 0 16px;
    /* When the radio button is checked, add a blue background */
    /* Create the indicator (the dot/circle - hidden when not checked) */
    /* Show the indicator (dot/circle) when checked */
    /* Style the indicator (dot/circle) */
}

.priority-box-set .box-container-set.active {
    border-color: var(--col-btn-primary);
}

.priority-box-set .box-container-set .container-custom {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    color: var(--col-secondary);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.priority-box-set .box-container-set .container-custom input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.priority-box-set .box-container-set .container-custom .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: var(--col-white);
    border-radius: 50%;
    border: 2px solid var(--col-body);
}

.priority-box-set .box-container-set .container-custom input:checked~.checkmark {
    background-color: var(--col-white);
    border: 2px solid var(--col-btn-primary);
}

.priority-box-set .box-container-set .checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.priority-box-set .box-container-set .container-custom input:checked~.checkmark:after {
    display: block;
}

.priority-box-set .box-container-set .container-custom .checkmark:after {
    left: 50%;
    transform: translate(-50%, 13.5%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--col-btn-primary);
}

@media screen and (min-width: 991.8px) {
    .ssc-main-content {
        width: calc(100dvw - 242px) !important;
        max-width: calc(100dvw - 242px) !important;
    }
}

@media screen and (max-width: 991.8px) {
    .ssc-main-content {
        padding: 16px 16px !important;
        flex-grow: 1;
        width: auto !important;
        margin-left: 74px;
        flex-shrink: inherit !important;
        width: calc(100dvw - 74px) !important;
    }

    #MenuSidebarMobileView4.show,
    #MenuSidebarMobileView.show,
    #MenuSidebarMobileView3.show {
        transform: translateX(14px) !important;
    }
}

@media (max-width : 640px) {

    #MenuSidebarMobileView4.show,
    #MenuSidebarMobileView3.show {
        min-width: 270px;
    }
}

/* height and width */
::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background-color: var(--col-br-dark);
    width: 2px;
    border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var(--col-btn-primary);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--col-btn-primary-hover);
}

.wrapper {
    width: 100%;
}

.wrapper.active .content {
    display: block;
    border: 1px solid var(--col-label);
    border-top: transparent;
}

.wrapper .select-btn {
    background-color: var(--col-select-white);
    height: 48px;
    padding: 0 16px;
    border-radius: 8px;
    border: 1px solid var(--col-label);
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: var(--col-C5D2E7);
}

.wrapper .content {
    background-color: var(--col-select-white);
    margin-top: -11px;
    padding: 16px;
    border-radius: 0 0 8px 8px;
    display: none;
    z-index: 10;
}

.wrapper .content .search {
    position: relative;
}

.wrapper .content .search input {
    height: 48px;
    width: 100%;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    text-align: left;
    outline: none;
    padding: 16px 16px 16px 38px;
    border: 1px solid var(--col-label);
    border-radius: 8px;
    color: var(--col-primary);
}

.wrapper .content .search input::placeholder {
    color: var(--col-C5D2E7);
}

.wrapper .content .search input:focus-visible {
    outline: none;
}

.wrapper .content .search img,
.wrapper .content .search .search-icon-svg {
    position: absolute;
    left: 15px;
    top: 15px;
}

.wrapper .content .options {
    margin-top: 16px;
    padding: 0;
    max-height: 190px;
    overflow-y: auto;
}

.wrapper .content .options li {
    height: 25px;
    cursor: pointer;
    padding: 8px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.wrapper .content .options li:hover {
    background-color: var(--col-E4FBFA);
}

.ssc-login .scc-login-right {
    background-color: var(--col-label);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

.ssc-login .scc-login-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100dvh;
}

.ssc-login .scc-login-left .extra-spacing-rl {
    padding-left: 16px;
    padding-right: 16px;
}

.ssc-login .scc-login-left .admin-form {
    border: 1px solid var(--col-br-dark);
    padding: 32px;
    border-radius: 16px;
    gap: 48px;
}

.ssc-login .scc-login-left .admin-form .admin-login-btn {
    border: 1px solid var(--col-br-light);
    padding: 12px 74px;
    gap: 20px;
    color: var(--col-4B5564);
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 19px;
    letter-spacing: 0em;
    border-radius: 166px;
}

.ssc-login .scc-login-left .cust-form {
    border: 1px solid var(--col-br-dark);
    padding: 32px 16px;
    border-radius: 16px;
}

.ssc-login .scc-login-left .cust-form h6.form-title {
    font-family: Inter;
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 13px;
    letter-spacing: 0em;
    color: var(--col-4B5564);
    margin-bottom: 40px;
}

.ssc-login .scc-login-left .cust-form .forget-link {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 15px;
    letter-spacing: 0.01em;
    text-decoration: none;
    color: var(--col-primary);
}

.ssc-login .scc-login-left .cust-form .form-bottom {
    gap: 25px;
}

.ssc-login .scc-login-left .cust-form .form-bottom .link {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    text-decoration: none;
    color: var(--col-btn-primary);
}

.ssc-login .scc-login-left .forget-form {
    border: 1px solid var(--col-br-dark);
    padding: 32px 16px;
    border-radius: 16px;
}

.ssc-login .scc-login-left .forget-form .forget-title-bar {
    margin-bottom: 40px;
}

.ssc-login .scc-login-left .forget-form .forget-title-bar h3 {
    font-size: var(--font-24);
    font-weight: var(--f-w-600);
    line-height: 32px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
}

.ssc-login .scc-login-left .forget-form .forget-title-bar h5 {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-4B5564);
}

.ssc-login .scc-login-left .forget-form .back-btn a {
    gap: 13px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 15px;
    letter-spacing: 0.01em;
    text-decoration: none;
    color: var(--col-primary);
}

.ssc-login .scc-login-left .forget-form .back-btn a p {
    margin-bottom: 2px;
}

.ssc-login .scc-login-left .user-input-section {
    margin-bottom: 40px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 374px;
    padding-left: 16px;
    padding-right: 16px;
}

.ssc-login .scc-login-left .user-input-section .input-set.input-set-gap {
    margin-bottom: 24px;
}

.ssc-login .scc-login-left .user-input-section .input-set label {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
    color: var(--col-secondary);
}

.ssc-login .scc-login-left .user-input-section .input-set input {
    border: 1px solid var(--col-br-dark);
    border-radius: 8px;
    padding: 12px 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
}

.ssc-login .scc-login-left .user-input-section .input-set input::placeholder {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-C5D2E7);
}

.ssc-login .scc-login-left .user-input-section .input-set input:focus {
    box-shadow: none;
    border: 1px solid var(--col-btn-primary);
}

.ssc-login .scc-login-left .user-input-section .input-set .eye-icon {
    top: 15px;
    right: 16px;
    background-color: transparent;
}

.ssc-login .scc-login-left .user-input-section .new-pwd-indicator {
    font-size: var(--font-12);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: -0.005em;
    color: var(--col-body);
}

.ssc-login .scc-login-left .user-input-section .new-pwd-indicator .indicator-dot {
    height: 8px;
    width: 8px;
    background-color: var(--col-label);
    margin-right: 10px;
    border-radius: 100%;
}

.ssc-login .scc-login-left .user-input-section .new-pwd-indicator .indicator-dot.dot-checked {
    background-color: var(--col-btn-primary) !important;
}

.ssc-login .scc-login-left .user-input-section .new-pwd-indicator .line-set {
    margin-bottom: 12px;
}

@media screen and (max-width: 1199.8px) {

    .scc-login-left .admin-form,
    .scc-login-left .cust-form,
    .scc-login-left .forget-form {
        padding: 16px !important;
    }
}

@media screen and (max-width: 991.8px) {

    .scc-login-left .admin-form,
    .scc-login-left .cust-form,
    .scc-login-left .forget-form {
        padding: 8px !important;
    }
}

@media screen and (max-width: 676.8px) {
    .scc-login-right {
        display: none;
    }
}

.form-footer {
    gap: 8px;
    margin-top: 20px;
}

.form-footer a.ssc-footer-link {
    text-decoration: none;
    font-size: var(--font-14);
    line-height: 1;
    color: var(--col-primary);
}

.form-footer h5 {
    margin-top: 5px;
}

.dashbaord-view-scroll {
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100dvh - 115px);
    scrollbar-gutter: stable;
    width: 100%;
}

.dashbaord-view-scroll .dashboard-header {
    padding: 32px;
    border: 1px solid var(--col-br-dark);
    border-radius: 8px;
    background-color: var(--col-F8FDFF);
}

.dashbaord-view-scroll .dashboard-header .title {
    font-size: var(--font-24);
    font-weight: var(--f-w-600);
    line-height: 32px;
    letter-spacing: 0.01em;
}

.dashbaord-view-scroll .dashboard-header .row {
    margin-left: -12px !important;
    margin-right: -12px !important;
}

.dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col {
    min-width: 300px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
}

@media (max-width:720px) {
    .dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col {
        min-width: fit-content !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .dashbaord-view-scroll .dashboard-header {
        padding: 16px;
    }
}

.dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col .box-container {
    border-radius: 8px;
    border: 1px solid var(--col-br-dark);
    gap: 20.5px;
}

.dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col .box-container .box-title {
    gap: 9px;
    height: 32px;
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-secondary);
}

.dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col .box-container .box-num-count {
    height: 32px;
}

.dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col .box-container .box-num-count h4 {
    font-size: var(--font-32);
    font-weight: var(--f-w-500);
    line-height: 40px;
    letter-spacing: -0.005em;
    color: var(--col-primary);
}

.dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col .box-container .box-num-count select {
    height: 32px;
    background-color: var(--col-E4FBFA);
    color: var(--col-secondary);
    border-radius: 4px;
    padding: 0 8px;
    border: none;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 15px;
    letter-spacing: 0.01em;
}

.dashbaord-view-scroll .dashboard-header .row .col-md-4.custom-col .box-container .box-num-count select:focus {
    outline: none;
}

.dashbaord-view-scroll .dashboard-main-content .row {
    margin-left: -12px !important;
    margin-right: -12px !important;
}

.dashbaord-view-scroll .dashboard-main-content .row .col-md-6.custom-col {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.dashbaord-view-scroll .dashboard-main-content .row .col-md-6.custom-col .card-container {
    gap: 24px;
    border: 1px solid var(--col-br-dark);
    border-radius: 8px;
}

.dashbaord-view-scroll .dashboard-main-content .row .col-md-6.custom-col .card-container .card-title-area h5 {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    margin-bottom: 0px;
}

.dashbaord-view-scroll .dashboard-main-content .row .col-md-6.custom-col .card-container .content-set-area {
    gap: 8px;
}

.dashbaord-view-scroll .dashboard-main-content .row .col-md-6.custom-col .card-container .content-set-area .content-set {
    height: 50px;
}

.dashbaord-view-scroll .dashboard-main-content .row .col-md-6.custom-col .card-container .content-set-area .content-set h5 {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0em;
    color: var(--col-primary);
}

.dashbaord-view-scroll .dashboard-main-content .row .col-md-6.custom-col .card-container .content-set-area .content-set span {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-body);
}

@media screen and (max-width: 991.8px) {
    .dashboard-header {
        padding: 20px;
    }

    .dashboard-header .row {
        margin-left: -4px !important;
        margin-right: -4px !important;
    }

    .dashboard-header .row .col-md-4.custom-col {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }

    .dashboard-main-content .row {
        margin-left: -4px !important;
        margin-right: -4px !important;
    }

    .dashboard-main-content .row .col-md-6.custom-col {
        padding-left: 4px !important;
        padding-right: 4px !important;
    }
}

.ssc-header {
    color: var(--col-secondary);
    padding: 16px 32px;
    border-bottom: 1px solid var(--col-br-dark);
    height: 72px;
    width: 100dvw;
    max-width: 100dvw;
}

.ssc-header .logo {
    padding: 4px 0px;
}

.ssc-header .ssc-header-right {
    gap: 24px;
}

.ssc-header .ssc-header-right .ssc-bell-btn {
    background-color: var(--col-white);
}

.ssc-header .ssc-header-right .ssc-bell-btn .notification-num {
    color: var(--col-white);
    background-color: var(--col-error);
    top: -3px;
    right: 0px;
    border-radius: 100%;
    font-size: 10px;
    font-weight: 500;
    width: 15px;
    height: 15px;
}

.ssc-header .ssc-header-right .ssc-profile-dropdown .ssc-header-dropdown {
    border: none;
    gap: 10.5px;
    background-color: var(--col-white);
}

.ssc-header .ssc-header-right .ssc-profile-dropdown .ssc-header-dropdown .img-area {
    border-radius: 100%;
    background-color: var(--col-label);
    width: 35px;
    height: 35px;
}

.ssc-header .ssc-header-right .ssc-profile-dropdown .ssc-header-dropdown .dropdown-arrow {
    width: 16px;
    height: 16px;
}

.ssc-header .ssc-header-right .ssc-profile-dropdown #MenuSidebarMobileView {
    position: fixed;
    right: 30px;
    top: 60px;
    border: 1px solid var(--col-br-dark);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
    z-index: 100;
    min-height: 265px;
    min-width: 249px;
    transform: translateX(330px);
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    border-radius: 18px;
}

.ssc-header .ssc-header-right .ssc-profile-dropdown #MenuSidebarMobileView.show {
    transform: translateX(0px);
}

.ssc-header .ssc-header-right .ssc-profile-dropdown #MenuSidebarMobileView .profile-img {
    border-radius: 100%;
    width: 56px;
    height: 56px;
    border: 1px solid var(--col-br-dark);
    margin-bottom: 19px;
}

.ssc-header .ssc-header-right .ssc-profile-dropdown #MenuSidebarMobileView .user-name {
    font-weight: var(--f-w-600);
    font-size: var(font-16);
    margin-bottom: 48px;
    line-height: 24px;
    letter-spacing: 0.01em;
}

.ssc-header .ssc-header-right .ssc-profile-dropdown #MenuSidebarMobileView .line-separtor {
    background-color: var(--col-br-dark);
    height: 1px;
}

.ssc-header .ssc-header-right .ssc-profile-dropdown #MenuSidebarMobileView .link-div {
    font-weight: var(--f-w-400);
    font-size: var(--font-14);
    line-height: 24px;
    text-decoration: none;
    color: var(--col-secondary);
}

@media screen and (max-width: 991.8px) {
    .ssc-header {
        padding: 16px 18px;
    }
}

.ssc-sliderbar {
    padding: 24px 10px;
    border-right: 1px solid var(--col-br-dark);
    width: 242px !important;
    max-width: 242px !important;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.ssc-sliderbar.collapse:not(.show) {
    display: block;
    flex-basis: auto;
}

.ssc-sliderbar .link-area {
    list-style-type: none;
}

.ssc-sliderbar .link-area .links-set .link-line {
    gap: 8px;
    text-decoration: none;
    color: var(--col-body);
    padding: 12px 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: var(--col-white);
    overflow: hidden;
}

.ssc-sliderbar .link-area .links-set .link-line span {
    word-break: keep-all;
}

.ssc-sliderbar .link-area .links-set .link-line svg {
    min-width: 16px;
    min-height: 16px;
}

.ssc-sliderbar .link-area .links-set .link-line.active,
.ssc-sliderbar .link-area .links-set .link-line:hover {
    color: var(--col-primary);
    background-color: var(--col-E4FBFA);
}

.ssc-sliderbar .link-area .links-set .link-line.active svg path,
.ssc-sliderbar .link-area .links-set .link-line.active rect,
.ssc-sliderbar .link-area .links-set .link-line.active mask,
.ssc-sliderbar .link-area .links-set .link-line:hover svg path,
.ssc-sliderbar .link-area .links-set .link-line:hover rect,
.ssc-sliderbar .link-area .links-set .link-line:hover mask {
    stroke: var(--col-primary);
}

@media screen and (max-width: 991.8px) {
    .ssc-sliderbar {
        position: fixed;
        left: 0px;
        top: 72px;
        z-index: 100;
        height: calc(100dvh - 72px) !important;
        transition: all ease-in-out 0.35s !important;
        width: 74px !important;
        max-width: 74px !important;
        scrollbar-gutter: stable;
    }

    .ssc-sliderbar.isImpersonated {
        top: 96px !important;
    }

    .ssc-sliderbar .link-line span {
        width: 0px;
        max-width: 0px;
        visibility: hidden;
        white-space: nowrap;
        transition: all ease-in-out 0.35s !important;
    }

    .ssc-sliderbar.show {
        width: 242px !important;
        max-width: 242px !important;
        background-color: var(--col-white);
    }

    .ssc-sliderbar.show .link-line span {
        width: 100%;
        max-width: 100%;
        visibility: visible;
        transition: all ease-in-out 0.35s !important;
    }
}

.ssc-slider-btn {
    top: 10px;
    left: 62px;
    border-radius: 100%;
    border: 1px solid var(--col-br-dark);
    max-width: 24px !important;
    width: 24px !important;
    max-height: 30px;
    z-index: 110;
    transition: all ease-in-out 0.35s !important;
}

.ssc-slider-btn.open {
    left: 230px;
}

.ssc-table-area {
    height: calc(100dvh - 320px);
    overflow: auto;
    position: relative;
}

.ssc-table-area table {
    background-color: var(--col-white);
}

.ssc-table-area table .grade-dot {
    width: 65px;
}

.ssc-table-area table thead {
    position: sticky;
    top: -1px;
    left: 0;
    z-index: 90;
}

.ssc-table-area table thead tr th {
    padding: 16px;
    color: var(--col-primary);
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 17px;
    letter-spacing: 0em;
    vertical-align: middle;
    background-color: var(--col-table-head);
}

.ssc-table-area table thead tr th.option-td {
    width: 84px;
    text-align: center;
}

.ssc-table-area table thead tr th.option-td-go-btn {
    width: 54px;
    text-align: center;
}

.ssc-table-area table thead tr th.tab-td {
    width: 410px;
}

@media screen and (max-width: 1300px) {
    .ssc-table-area table thead tr th.tab-td {
        width: 360px;
    }
}

@media screen and (max-width: 1200px) {
    .ssc-table-area table thead tr th.tab-td {
        width: 300px;
    }
}

@media screen and (max-width: 991px) {
    .ssc-table-area table thead tr th.tab-td {
        width: auto;
    }
}

@media screen and (min-width: 991px) {

    .ssc-table-area table thead tr th.csm-name,
    .ssc-table-area table thead tr th.admin-name {
        width: 190px;
        max-width: 190px;
    }
}

@media screen and (min-width: 991px) {

    .ssc-table-area table thead tr th.csm-id,
    .ssc-table-area table thead tr th.admin-id {
        width: 100px;
        max-width: 100px;
    }
}

@media screen and (min-width: 991px) {

    .ssc-table-area table thead tr th.csm-phone,
    .ssc-table-area table thead tr th.admin-phone {
        width: 390px;
        max-width: 390px;
    }
}

@media screen and (min-width: 700px) {
    .ssc-table-area table thead tr th.hire-client {
        width: 300px;
        max-width: 300px;
        min-width: 300px;
    }
}

@media screen and (min-width: 1300px) {
    .ssc-table-area table thead tr th.hire-client {
        width: 200px;
        max-width: 200px;
        min-width: 200px;
    }
}

@media screen and (min-width: 991px) {
    .ssc-table-area table thead tr th.tpa-area {
        width: 490px;
        max-width: 490px;
    }
}

@media screen and (min-width: 1300px) {
    .ssc-table-area table thead tr th.tpa-area {
        width: 590px;
        max-width: 590px;
    }
}

.ssc-table-area table thead tr th:first-child {
    border-radius: 16px 0 0 0;
}

.ssc-table-area table thead tr th:last-child {
    border-radius: 0 16px 0 0;
}

.ssc-table-area table tbody tr td {
    padding: 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    color: var(--col-secondary);
    letter-spacing: 0.005em;
}

.ssc-table-area table tbody tr td.email-td {
    word-break: break-all;
}

.ssc-table-area table tbody tr td .table-pill {
    display: inline;
    padding: 0 8px;
    background-color: var(--col-E4FBFA);
    border-radius: 1000px;
    border: 1px solid var(--col-br-dark);
    line-height: 1;
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    text-align: center;
    box-sizing: border-box;
}

.ssc-table-area table tbody tr td .tab-set {
    gap: 4px;
}

.ssc-table-area table tbody tr td .tab-set span {
    padding: 4px 8px;
    height: 17px;
    border-radius: 1000px;
    border: 1px soild var(--col-br-dark);
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    background-color: var(--col-E4FBFA);
    display: flex;
    align-items: center;
}

.ssc-table-area table tbody tr td.option-td {
    width: 84px;
}

.ssc-table-area table tbody tr td.option-td button.bg-white {
    background-color: transparent !important;
}

.ssc-table-area table tbody tr td.option-td .options-set {
    gap: 16px;
}

.ssc-table-area table tbody tr td.option-td-go-btn {
    width: 54px;
    text-align: center;
}

.ssc-table-area table tbody tr td.csm-img-td .img-set {
    gap: 10px;
}

.ssc-table-area table tbody tr td.csm-img-td .img-set .img-tag {
    border-radius: 100%;
}

.ssc-table-area table tbody tr td.tab-td {
    width: 410px;
}

@media screen and (max-width: 1300px) {
    .ssc-table-area table tbody tr td.tab-td {
        width: 360px;
    }
}

@media screen and (max-width: 1200px) {
    .ssc-table-area table tbody tr td.tab-td {
        width: 300px;
    }
}

@media screen and (max-width: 991px) {
    .ssc-table-area table tbody tr td.tab-td {
        width: auto;
    }
}

.ssc-table-area table tbody tr:nth-child(even) {
    background-color: var(--col-table-head);
}

.ssc-table-area table tbody tr:nth-child(odd) {
    background-color: var(--col-white);
}

.tab-open-area {
    transform: translateX(700px);
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 95;
    height: calc(100dvh - 295px);
    width: 660px;
    background-color: var(--col-white);
    transition: all ease-in-out 0.3s;
}

.tab-open-area.show {
    transform: translateX(0px);
}

.tab-open-area .sub-title {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    color: var(--col-primary);
    margin-bottom: 24px;
}

.tab-open-area .close-btn {
    position: absolute;
    left: -15px;
    top: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    z-index: 11;
    box-shadow: var(--shadow);
}

.tab-open-area .content-area {
    overflow-x: hidden;
    height: calc(100dvh - 295px);
    overflow-y: auto;
}

.tab-open-area .content-area .delete-icon {
    position: absolute;
    right: 10px;
    top: 52px;
}

.tab-open-area .content-area h6 {
    font-size: var(--font-18);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 16px;
    color: var(--col-primary);
}

.tab-open-area .content-area .left-side .title {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-primary);
}

.tab-open-area .content-area .left-side .documents-area {
    margin-bottom: 20px;
}

.tab-open-area .content-area .left-side .documents-area .timing {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-body);
}

.tab-open-area .content-area .left-side .documents-area .doc {
    border: 1px solid var(--col-br-dark);
    border-radius: 8px;
}

.tab-open-area .content-area .right-side .detail-area {
    gap: 24px;
    margin-bottom: 24px;
}

.tab-open-area .content-area .right-side .detail-area .title {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-body);
    margin-bottom: 8px;
}

.tab-open-area .content-area .right-side .detail-area .text {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: 0.005em;
    color: var(--col-primary);
}

.tab-open-area .content-area .right-side .detail-area .text .dot {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    background-color: var(--col-error);
    margin-right: 10px;
}

.tab-open-area .content-area .right-side .detail-area .text img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 100px;
}

.tab-open-area .list-view-all-btn {
    color: var(--col-btn-primary) !important;
    background-color: transparent !important;
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    margin-top: 8px;
}

.custom-modal-doc-cato .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-51.5%, -55%) !important;
}

.custom-modal-doc-cato .modal-dialog .modal-content {
    border-radius: 16px;
    padding: 32px;
}

.custom-modal-doc-cato .modal-dialog .modal-content .head-title-edit {
    font-size: var(--font-18);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
}

.custom-modal-doc-cato .modal-dialog .modal-body .input-content {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 19.5px;
    letter-spacing: 0.01em;
    color: var(--col-secondary);
}

.custom-modal-doc-cato .modal-dialog .modal-body .input-text {
    border: 1px solid var(#e2f1f7);
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
    height: 48px;
}

.custom-modal-doc-cato .modal-dialog .modal-body .input-text:focus {
    box-shadow: none;
    border: 1px solid var(--col-btn-primary);
}

.custom-modal-doc-cato .modal-dialog .modal-body .form-control.input-area {
    border: 1px solid var(--col-btn-primary);
    padding: 0 12px;
    height: 48px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
    margin: 48px 0;
}

.custom-modal-doc-cato .modal-dialog .modal-body .form-control.input-area:focus {
    box-shadow: none;
}

.custom-modal-doc-cato .modal-dialog .modal-body .form-control.input-area::placeholder {
    color: var(--col-C5D2E7);
}

.custom-modal-doc-cato .modal-dialog .modal-body h3.del-title {
    color: var(--col-primary);
    font-size: var(--font-32);
    font-weight: var(--f-w-500);
    line-height: 42px;
    letter-spacing: -0.005em;
    margin-top: 33px;
}

.custom-modal-doc-cato .modal-dialog .modal-body p.del-para {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 24px;
    color: var(--col-4B5564);
    margin-bottom: 48px;
    margin-top: 32px;
}

.custom-modal-doc-cato .modal-dialog .modal-btn {
    gap: 16px;
}

.custom-modal-doc-cato .modal-dialog .modal-btn .ssc-primary-green-btn {
    height: 40px;
    border-radius: 4px;
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
}

.custom-modal-doc-cato .modal-dialog .modal-btn .ssc-secondary-white-btn {
    height: 40px;
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
}

.multi-clients {
    gap: 8px;
}

.multi-clients span {
    line-height: 1;
}

.customer-area-btn {
    gap: 10px;
    margin-bottom: 50px;
}

.customer-area-btn .w-auto.ssc-secondary-white-btn {
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
    height: 36px;
}

.customer-area-btn .w-auto.ssc-primary-green-btn {
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
    height: 36px;
    border-radius: 4px;
}

.customer-area-btn .btn-del {
    margin-left: 14px;
}

.empty-doc img {
    margin-bottom: 44px;
}

.empty-doc span.text-line {
    color: var(--col-body);
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 20px;
}

.filled-doc {
    gap: 24px;
}

.filled-doc span {
    color: var(--col-body);
    font-size: var(--font-12);
    font-weight: var(--f-w-400);
    line-height: 16px;
    letter-spacing: 0.01em;
}

.news-feed-btn {
    margin-top: 28px;
}

.news-feed-btn .w-auto.ssc-secondary-white-btn {
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
    height: 36px;
}

.icon1 {
    left: 10px;
}

.icon2 {
    right: 10px;
}

.doc-hov {
    margin-top: 24px;
    border-radius: 4px;
}

.doc-hov .icon1,
.doc-hov .icon2 {
    position: absolute;
    bottom: 10px;
    z-index: 14;
    cursor: pointer;
    display: none;
}

.doc-hov .cross-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 14;
    cursor: pointer;
    display: none;
}

.doc-hov:hover::after {
    background-color: rgba(0, 0, 0, 0.6);
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 12;
    border-radius: 4px;
}

.doc-hov:hover .icon1 {
    display: block !important;
}

.doc-hov:hover .icon2 {
    display: block !important;
}

.doc-hov:hover .cross-icon {
    display: block !important;
}

.title-btn {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    gap: 10px;
    margin-bottom: 32px;
    margin-top: 1rem;
}

.profile-box .profile-img {
    border: 1px solid var(--col-br-dark);
    border-radius: 16px;
    padding: 24px;
}

.profile-box .profile-img img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    margin-bottom: 24px;
}

.profile-box .profile-img .img-edit {
    position: absolute;
    bottom: 21px;
    right: 0px;
}

.profile-box .profile-img .img-edit img {
    width: 24px;
    height: 24px;
    margin-bottom: 0px;
    cursor: pointer;
}

.profile-box .profile-img h4 {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-secondary);
}

.profile-box .profile-img p {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
}

.tabs-set .tabs-row {
    border-bottom: 1px solid var(--col-br-dark);
    width: 100%;
}

.tabs-set .tabs-row .link-profile {
    color: var(--col-primary);
    height: 42px;
    padding: 0 16px;
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0em;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.tabs-set .tabs-row .link-profile.active {
    color: var(--col-btn-primary);
}

.tabs-set .tabs-row .link-profile.active::before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: -1px;
    left: 0px;
    height: 1px;
    background-color: var(--col-btn-primary);
}

.tabs-set .tabs-row .link-profile:hover {
    color: var(--col-btn-primary);
}

.tabs-set .form-area {
    height: calc(100dvh - 480px);
    overflow-y: auto;
    overflow-x: hidden;
}

.tabs-set .form-area .pwd-sub-para {
    font-size: var(--font-12);
    font-weight: var(--f-w-400);
    line-height: 18px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
    color: var(--col-body);
}

.tabs-set .form-area .eye-icon2 {
    top: 13px;
    right: 16px;
    background-color: transparent;
}

.tabs-set .form-area .form-title {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0em;
}

.tabs-set .form-area .input-set label {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: 0.01em;
    margin-bottom: 8px;
    color: var(--col-secondary);
}

.tabs-set .form-area .input-set .form-control {
    padding: 0px 16px;
    height: 48px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
}

.tabs-set .form-area .input-set .form-control:focus {
    box-shadow: none;
    border: 1px solid var(--col-btn-primary);
}

.tabs-set .form-area .input-set .form-control::placeholder {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-C5D2E7);
}

.tabs-set .form-area .form-button {
    background-color: var(--col-btn-primary);
    border-radius: 4px;
    height: 40px;
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 0 16px;
    color: var(--col-white);
    transition: all 0.3s ease-in-out;
}

.tabs-set .form-area .form-button:hover {
    background-color: var(--col-btn-primary-hover);
}

.tabs-set .form-area .form-button.disabled {
    background-color: var(--col-label);
    cursor: default;
}

@media screen and (max-width: 991.8px) {
    .profile-box {
        padding: 24px;
    }

    .profile-img {
        padding: 16px;
    }
}

@media screen and (max-width: 767.8px) {
    .profile-box {
        padding: 16px;
    }
}

.customer-dashboard-view {
    overflow: hidden;
    height: calc(100dvh - 117px);
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    padding-right: 0.5rem;
}

/* .customer-dashboard-view .main-content,
.customer-dashboard-view .main-content-inner {
    margin: 0 8px;
} */

.customer-dashboard-view .col-9.custom-col-set,
.customer-dashboard-view .col-3.custom-col-set,
.customer-dashboard-view .col-2.custom-col-set,
.customer-dashboard-view .col-10.custom-col-set,
.customer-dashboard-view .col-6.custom-col-set {
    padding: 0 16px;
}

.customer-dashboard-view .mb-32 {
    margin-bottom: 32px;
}

.customer-dashboard-view .top-left {
    border: 1px solid var(--col-br-dark);
    padding: 16px 16px 23px 16px;
    border-radius: 4px;
}

.customer-dashboard-view .top-left img {
    border-radius: 100%;
}

.customer-dashboard-view .top-left h5 {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-body);
}

.customer-dashboard-view .top-left h4 {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-primary);
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 24px;
}

.customer-dashboard-view .top-right {
    background-color: var(--col-F8FDFF);
    border: 1px solid var(--col-br-dark);
    padding: 24px 32px;
    border-radius: 8px;
}

.customer-dashboard-view .top-right h3 {
    font-size: var(--font-24);
    font-weight: var(--f-w-500);
    line-height: 32px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
}

.customer-dashboard-view .top-right .row {
    margin: 0 -8px;
}

.customer-dashboard-view .top-right .row .col-lg-4.col-6 {
    padding: 0 8px;
}

.customer-dashboard-view .top-right .label-box {
    color: var(--col-primary);
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0em;
    padding: 0px 16px;
    height: 32px;
    border-radius: 4px;
}

.customer-dashboard-view .top-right .label-box span {
    margin-bottom: 1px;
}

.customer-dashboard-view .top-right .label-box .dot {
    border-radius: 100%;
    height: 10px;
    width: 10px;
    min-width: 10px;
}

.customer-dashboard-view .top-right .label-box .red-dot {
    background-color: var(--col-error);
}

.customer-dashboard-view .top-right .label-box .yellow-dot {
    background-color: var(--col-warning);
}

.customer-dashboard-view .top-right .label-box .green-dot {
    background-color: var(--col-009262);
}

.customer-dashboard-view .top-right .label-box img {
    margin-bottom: 1px;
}

.customer-dashboard-view .top-right .red-label {
    border: 1px solid var(--col-F0857D);
    background-color: var(--col-FFF1F0);
    transition: all ease-in-out 0.3s;
}

.customer-dashboard-view .top-right .red-label:hover {
    background-color: var(--col-hov-f7d2cf);
}

.customer-dashboard-view .top-right .yellow-label {
    border: 1px solid var(--col-FFC670);
    background-color: var(--col-FDF5ED);
    transition: all ease-in-out 0.3s;
}

.customer-dashboard-view .top-right .yellow-label:hover {
    background-color: var(--col-hov-f9d9ba);
}

.customer-dashboard-view .top-right .green-label {
    border: 1px solid var(--col-84DFC1);
    background-color: var(--col-E4FBFA);
    transition: all ease-in-out 0.3s;
}

.customer-dashboard-view .top-right .green-label:hover {
    background-color: var(--col-hov-c5fdfa);
}

.customer-dashboard-view .bottom-left .card-container,
.customer-dashboard-view .bottom-right .card-container {
    gap: 24px;
    border: 1px solid var(--col-br-dark);
    border-radius: 8px;
}

.customer-dashboard-view .bottom-left .card-container .card-title-area h5,
.customer-dashboard-view .bottom-right .card-container .card-title-area h5 {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    margin-bottom: 0px;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.customer-dashboard-view .bottom-left .card-container .content-set-area,
.customer-dashboard-view .bottom-right .card-container .content-set-area {
    gap: 8px;
    height: calc(100dvh - 503px);
    overflow: auto;
}

.customer-dashboard-view .bottom-left .card-container .content-set-area .content-set,
.customer-dashboard-view .bottom-right .card-container .content-set-area .content-set {
    height: 50px;
    margin-bottom: 10px;
}

.customer-dashboard-view .bottom-left .card-container .content-set-area .content-set h5,
.customer-dashboard-view .bottom-right .card-container .content-set-area .content-set h5 {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0em;
    color: var(--col-primary);
}

.customer-dashboard-view .bottom-left .card-container .content-set-area .content-set span,
.customer-dashboard-view .bottom-right .card-container .content-set-area .content-set span {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-body);
}

.customer-dashboard-view .bottom-right .content-set {
    border-bottom: 1px solid var(--col-br-dark);
    padding: 8px 0px;
}

.customer-dashboard-view .bottom-right .content-set:first-child {
    padding-top: 0px !important;
}

.customer-dashboard-view .bottom-right .content-set:last-child {
    border-bottom: none;
}

.customer-dashboard-view .bottom-right .content-set .icon-spacing {
    width: 32px;
    height: 32px;
    transition: all 0.3s ease-in-out;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
}

.customer-dashboard-view .bottom-right .content-set .icon-spacing .cross-icon {
    display: none;
    position: absolute;
}

.customer-dashboard-view .bottom-right .content-set .icon-spacing:hover {
    background-color: var(--col-E4FBFA);
}

.customer-dashboard-view .bottom-right .content-set .icon-spacing:hover .cross-icon {
    display: block;
    top: 0px;
    right: 0px;
}

.customer-dashboard-view .bottom-right p {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 18px;
    letter-spacing: 0em;
    color: var(--col-secondary);
}

.customer-dashboard-view .bottom-right .without-doc {
    gap: 44px;
    margin-top: 44px;
}

.customer-dashboard-view .bottom-right .without-doc p {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--col-body);
}

.customer-dashboard-view .bottom-right .col-dot {
    border-radius: 100%;
    height: 10px;
    width: 10px;
    margin-right: 16px;
    min-width: 10px;
}

.customer-dashboard-view .bottom-right .red-dot {
    background-color: var(--col-error);
}

.customer-dashboard-view .bottom-right .green-dot {
    background-color: var(--col-009262);
}

.customer-dashboard-view .bottom-right .blue-dot {
    background-color: var(--col-1145C8);
}

.customer-dashboard-view .bottom-right .yellow-dot {
    background-color: var(--col-warning);
}

.customer-dashboard-view .ssc-activity-bar {
    gap: 32px;
}

.customer-dashboard-view .ssc-activity-bar .activity-main {
    border-radius: 8px;
    border: 1px solid var(--col-br-dark);
    padding: 24px 12px;
}

.customer-dashboard-view .ssc-activity-bar .activity-main .content-header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    span {
        font-size: var(--font-16);
        font-weight: var(--f-w-500);
    }
}

.customer-dashboard-view .ssc-activity-bar .activity-main .activity-list {
    padding: 0 12px;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100dvh - 300px);
}

.customer-dashboard-view .ssc-activity-bar .activity-main .activity-list .list-container {
    gap: 24px;
}

.customer-dashboard-view .ssc-activity-bar .activity-main .activity-list .list-container .list {
    gap: 16px;
}

.customer-dashboard-view .ssc-activity-bar .ssc-secondary-white-btn {
    font-size: var(--font-16) !important;
    margin-bottom: 1rem;
}

@media screen and (max-width: 1199.8px) {

    .main-content,
    .main-content-inner {
        margin: 0 -12px !important;
    }

    .col-9.custom-col-set,
    .col-3.custom-col-set,
    .col-2.custom-col-set,
    .col-10.custom-col-set,
    .col-6.custom-col-set {
        padding: 0 12px !important;
    }

    .mb-32 {
        margin-bottom: 24px !important;
    }

    .top-right {
        padding: 12px 16px !important;
    }

    .bottom-right .col-dot {
        margin-right: 10px !important;
    }

    .bottom-left .content-set-area,
    .bottom-right .content-set-area {
        height: calc(100dvh - 518px) !important;
    }

    .activity-main {
        padding: 24px 10px !important;
    }

    .activity-main .content-header {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }

    .activity-main .activity-list {
        padding: 0 10px !important;
    }

    .activity-main .activity-list .list {
        gap: 12px !important;
    }
}

@media screen and (max-width: 991.8px) {

    .bottom-left .content-set-area,
    .bottom-right .content-set-area {
        height: calc(100dvh - 503px) !important;
    }
}

#MenuSidebarMobileView3 {
    padding: 24px;
    border-radius: 16px;
    position: fixed;
    right: 30px;
    bottom: 0px;
    border: 1px solid var(--col-br-dark);
    z-index: 100;
    height: 605px !important;
    min-width: 360px;
    max-width: 360px;
    transform: translateX(390px);
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    box-shadow: var(--shadow);
}

#MenuSidebarMobileView3.show {
    transform: translateX(0px);
    margin-bottom: 2rem;
}

#MenuSidebarMobileView3 h2 {
    font-size: var(--font-24);
    font-weight: var(--f-w-500);
    line-height: 32px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
}

#MenuSidebarMobileView3 .rating p {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-body);
}

#MenuSidebarMobileView3 .rating .ssc-rating-set {
    gap: 8px;
    margin-bottom: 35.5px;
}

#MenuSidebarMobileView3 .ssc-enchanced {
    margin-bottom: 32px;
}

#MenuSidebarMobileView3 .ssc-enchanced h3 {
    font-size: var(--font-18);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
}

#MenuSidebarMobileView3 .ssc-enchanced .pills-set {
    gap: 10px;
}

#MenuSidebarMobileView3 .ssc-enchanced .pills-set .pills {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    height: 41px;
    padding: 0 16px;
    border-radius: 8px;
    background-color: var(--col-E4FBFA);
    transition: all ease-in-out 0.3s;
}

#MenuSidebarMobileView3 .ssc-enchanced .pills-set .pills:hover {
    background-color: var(--col-5DEADE);
}

#MenuSidebarMobileView3 .ssc-enchanced .pills-set .pills.active {
    background-color: var(--col-5DEADE);
}

#MenuSidebarMobileView3 .ssc-comments label {
    margin-bottom: 8px;
}

#MenuSidebarMobileView3 .ssc-comments textarea {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    border: 1px solid var(--col-br-dark);
    border-radius: 8px;
    padding: 12px;
    width: 100%;
    height: 123px;
    resize: none;
}

#MenuSidebarMobileView3 .ssc-comments textarea::placeholder {
    color: var(--col-C5D2E7);
}

#MenuSidebarMobileView3 .ssc-comments textarea:focus-visible {
    outline: none;
}

#MenuSidebarMobileView4 {
    border-radius: 16px;
    position: fixed;
    right: 30px;
    top: 60px;
    border: 1px solid var(--col-br-dark);
    z-index: 100;
    height: auto !important;
    min-width: 360px;
    max-width: 360px;
    transform: translateX(390px);
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    box-shadow: var(--shadow);
}

#MenuSidebarMobileView4.show {
    transform: translateX(0px);
}

#MenuSidebarMobileView4 .notification-list-custom {
    overflow: auto;
    max-height: calc(100dvh - 250px);
}

#MenuSidebarMobileView4 .notification-list-custom .notification-text.bar {
    border-bottom: 1px solid var(--col-br-light);
}

#MenuSidebarMobileView4 .all-notification-page-btn {
    text-decoration: none;
    color: var(--col-btn-primary);
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 15px;
    letter-spacing: 0.01em;
    width: 82px;
}

.notification-title {
    border-bottom: 1px solid var(--col-br-light);
}

.notification-title h5 {
    font-size: var(--font-18);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    margin-right: 8px;
}

.notification-title span {
    font-size: var(--font-10);
    font-weight: var(--f-w-500);
    line-height: 12px;
    letter-spacing: 0.04em;
    color: var(--col-4B5564);
    padding: 0 8px;
    border-radius: 1666px;
    border: 1px solid var(--col-FF5A4F);
    background-color: var(--col-FFCCDB);
    height: 20px;
    white-space: nowrap;
}

.notification-title .link-text {
    color: var(--col-btn-primary);
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 15px;
    letter-spacing: 0.01em;
    cursor: pointer;
}

.notification-title .tabs-filters {
    height: 58px;
    margin-bottom: -16px;
}

.notification-title .tabs-filters .tab-btn {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--col-primary);
    padding: 0 16px;
    height: 58px;
}

.notification-title .tabs-filters .tab-btn.active {
    color: var(--col-btn-primary);
}

.notification-title .tabs-filters .tab-btn.active::before {
    position: absolute;
    content: '';
    bottom: -1px;
    left: 0px;
    height: 1px;
    width: 100%;
    background-color: var(--col-btn-primary);
}

.notification-title .tabs-filters .tab-btn:hover {
    color: var(--col-btn-primary);
}

.notification-title .tabs-filters .filter-btn {
    border: 1px solid var(--col-E9E9E9);
    padding: 0 16px;
    height: 36px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    border-radius: 4px;
    margin-left: 77px;
}

.notification-list {
    overflow: auto;
    height: calc(100dvh - 210px);
}

.notification-list-custom .notification-text {
    cursor: pointer;
}

.notification-list-custom .notification-text.new-notification {
    background-color: var(--col-E4FBFA);
}

.notification-list-custom .notification-text.page-text {
    margin-bottom: 8px;
}

.notification-list-custom .notification-text.page-text:last-child {
    margin-bottom: 0px;
}

.notification-list-custom .notification-text .title {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    margin-bottom: 12px;
}

.notification-list-custom .notification-text .text {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 16px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
}

.notification-list-custom .notification-text .notify-mark-dot {
    width: 12px;
    height: 12px;
}

.notification-list-custom .notification-text .notify-mark-dot .dot {
    background-color: var(--col-btn-primary);
    border-radius: 100%;
    width: 12px;
    height: 12px;
}

@media screen and (max-width: 1199.8px) {
    .tab-btn {
        padding: 0 5px !important;
    }

    .filter-btn {
        margin-left: 10px !important;
    }
}

.doc-request-box {
    border: 1px solid var(--col-label);
    border-radius: 16px;
    padding: 32px;
    margin-bottom: 32px;
    box-shadow: var(--shadow);
    scrollbar-gutter: stable;

    & form {
        padding-inline: 4px;
    }
}

.doc-request-box form {
    height: calc(100dvh - 310px);
    overflow-x: hidden;
    overflow-y: auto;
}

.doc-request-box h5 {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-secondary);
    margin-bottom: 8px;
}

.doc-request-box .pills-selected-area {
    gap: 8px;
}

.doc-request-box .pills-selected-area .pills-selected {
    height: 28px;
    padding: 0 12px;
    border: 1px solid var(--col-btn-primary);
    font-size: var(--font-11);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0.01em;
    background-color: var(--col-E4FBFA);
    border-radius: 8888px;
}

.doc-request-box .pills-selected-area .pills-selected img {
    cursor: pointer;
}

.doc-request-box .form-control {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
}

.doc-request-box .form-control::placeholder {
    color: var(--col-C5D2E7);
}

.doc-request-box .form-control:focus {
    box-shadow: none;
}

.doc-request-box .ssc-primary-green-btn.upload-file-btn {
    width: auto !important;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--col-white);
    height: 36px;
}

.doc-request-box .ssc-primary-green-btn.add-upload-file-btn {
    width: auto !important;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--col-white);
    border-radius: 100%;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 0px;
}

.outter-box-btns {
    gap: 8px;
}

.outter-box-btns .ssc-secondary-white-btn.cancel-btn {
    height: 40px;
    border-radius: 4px !important;
    font-size: var(--font-16) !important;
    font-weight: var(--f-w-600) !important;
    line-height: 24px;
    letter-spacing: 0.01em;
}

.outter-box-btns .ssc-primary-green-btn.submit-btn {
    width: auto !important;
    height: 40px;
    border-radius: 4px !important;
    font-size: var(--font-16) !important;
    font-weight: var(--f-w-600) !important;
    line-height: 24px;
    letter-spacing: 0.01em;
}

.custom-modal-doc-req .modal-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.custom-modal-doc-req .modal-dialog .modal-content {
    border-radius: 16px;
}

.custom-modal-doc-req .modal-dialog .modal-body h5 {
    margin-bottom: 49px;
    font-size: var(--font-32);
    font-weight: var(--f-w-500);
    line-height: 42px;
    letter-spacing: -0.005em;
}

.custom-modal-doc-req .modal-dialog .modal-btn {
    gap: 10px;
}

.custom-modal-doc-req .modal-dialog .modal-btn .ssc-primary-green-btn {
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    height: 40px;
    border-radius: 4px;
}

.custom-modal-doc-req .modal-dialog .modal-btn .ssc-secondary-white-btn {
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    height: 40px;
    border-radius: 4px;
}

.customer-from-content {
    margin-top: 32px;
}

.customer-from-content label {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 19.5px;
    letter-spacing: 0.01em;
    color: var(--col-secondary);
}

.customer-from-content .form-control.input-area-set {
    height: 48px;
    padding: 0 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
}

.customer-from-content .form-control.input-area-set:focus {
    border: 1px solid var(--col-btn-primary);
    box-shadow: none !important;
}

.customer-from-content .wrapper .content .options {
    margin-top: 16px;
    padding: 0;
    max-height: 70px;
    overflow-y: auto;
}

.pill-area-content-modal {
    gap: 8px;
}

.pill-area-content-modal .pills-tab-area {
    gap: 4px;
    height: 28px;
    padding: 0 12px;
    border: 1px solid var(--col-btn-primary);
    font-size: var(--font-11);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0.01em;
    background-color: var(--col-E4FBFA);
    border-radius: 8888px;
}

@media (min-width: 991.8px) {
    .modal-dialog.custom-wid {
        max-width: 740px;
    }
}

@media (min-width: 767.8px) {
    .modal-dialog.custom-wid {
        max-width: 600px;
    }
}

.title-btn button {
    font-size: var(--font-18);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
}

.ssc-newsfeed-box .name-box-set {
    background-color: var(--col-EFFAFF);
    border-radius: 8px;
}

.ssc-newsfeed-box .name-box-set .title {
    margin-bottom: 8px;
    color: var(--col-body);
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 19.5px;
    letter-spacing: 0.01em;
}

.ssc-newsfeed-box .name-box-set .text {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
}

.ssc-newsfeed-box .newsfeed-top-btn button {
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
    height: 36px;
}

.empty-set p {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 20px;
    margin-top: 43px;
    margin-bottom: 43px;
    color: var(--col-body);
}

.empty-set .ssc-primary-green-btn {
    height: 36px;
    border-radius: 4px;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
}

.add-newsfeed-text {
    padding: 12px 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 8px;
}

.add-newsfeed-text::placeholder {
    color: var(--col-body);
}

.add-newsfeed-text:focus {
    border: 2px solid var(--col-btn-primary);
    box-shadow: none;
}

.add-newsfeed-text:focus-visible {
    outline: none;
}

.new-table-responsiveness {
    margin-top: 12.5px;
}

.new-table-responsiveness .ssc-table-area {
    height: calc(100dvh - 257px) !important;
}

.new-table-responsiveness-reg .ssc-table-area {
    height: calc(100dvh - 160px) !important;
}

.new-table-responsiveness-scorecard {
    margin-top: 13px;
}

.new-table-responsiveness-scorecard .ssc-table-area {
    height: calc(100dvh - 270px) !important;
}

.new-table-responsiveness-scorecard .ssc-table-area .tab-pill-scorecard {
    height: 17px;
    padding: 0 8px;
    background-color: var(--col-E4FBFA);
    border-radius: 1000px;
    border: 1px solid var(--col-br-dark);
    line-height: 1;
}

.left-content-box {
    border: 1px solid var(--col-br-dark);
    padding: 16px;
    border-radius: 16px;
}

.left-content-box .scroll-set {
    padding: 16px;
    overflow-y: auto;
    gap: 32px;
    overflow-x: hidden;
    max-height: calc(100dvh - 265px);
}

.left-content-box .top-labels {
    column-gap: 56px;
    row-gap: 16px;
    flex-wrap: wrap;
}

.left-content-box .top-labels .label-set p {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 24px;
    color: var(--col-secondary);
    margin-bottom: 16px;
}

.left-content-box .top-labels .label-set .name {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 1;
    color: var(--col-primary);
    margin-bottom: 16px;
}

.left-content-box .top-labels .label-set .name:last-child {
    margin-bottom: 0px;
}

.left-content-box .alert-box-text {
    padding: 8px;
    border-radius: 4px;
    gap: 8px;
}

.left-content-box .alert-box-text .title {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
}

.left-content-box .alert-box-text .sub-title {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 1;
    color: var(--col-primary);
}

.left-content-box .alert-box-text.red {
    background-color: var(--col-FFF1F0);
}

.left-content-box .alert-box-text.red .title {
    color: var(--col-FF004D);
}

.left-content-box .notes-section h6 {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 24px;
    color: var(--col-secondary);
}

.left-content-box .notes-section p {
    font-size: var(--font-14);
    font-weight: var(--f-w-400);
    line-height: 21px;
    color: var(--col-primary);
    margin: 11px 0;
}

.left-content-box .doc-submit .doc-upload-add .doc-hov {
    margin-top: 0px !important;
}

.left-content-box .doc-submit .doc-upload-add .add-btn-area {
    max-height: 40px;
    max-width: 40px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.left-content-box .doc-submit h6 {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 1;
    color: var(--col-primary);
}

.left-content-box .upload-btn {
    padding: 10px 16px;
    border-radius: 4px;
    height: 36px;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
}

.right-content-box {
    border: 1px solid var(--col-br-dark);
    padding: 16px;
    border-radius: 16px;
}

.right-content-box .scroll-right-div {
    padding: 16px;
    max-height: calc(100dvh - 225px);
    overflow-y: auto;
    overflow-x: hidden;
}

.right-content-box .scroll-right-div h6 {
    font-size: var(--font-14);
    font-weight: var(--f-w-500);
    line-height: 24px;
    color: var(--col-primary);
}

@media screen and (max-width: 991.8px) {

    .left-content-box,
    .right-content-box {
        padding: 8px;
    }
}

.title-btn .ssc-primary-green-btn {
    height: 36px;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
    border-radius: 4px;
    color: var(--col-white);
}

.ssc-scorecard-box .name-box-set {
    background-color: var(--col-EFFAFF);
    border-radius: 8px;
}

.ssc-scorecard-box .name-box-set .title {
    margin-bottom: 8px;
    color: var(--col-body);
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 19.5px;
    letter-spacing: 0.01em;
}

.ssc-scorecard-box .name-box-set .dropdown-name {
    height: 48px;
    width: 70%;
    padding: 0 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    background-color: var(--col-white);
    color: var(--col-primary);
    border: 1px solid var(--col-br-dark);
    border-radius: 8px;
    appearance: none;
    background-image: url('../img/icons/arrow-dropdown.svg');
    background-repeat: no-repeat;
    background-position: right 1rem top 50%;
    background-size: 0.65rem auto;
}

.ssc-scorecard-box .upper-user-text {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-2D343F);
    margin-right: 14px;
}

.ssc-scorecard-box .ssc-search-box .search-box {
    min-width: 343px;
    height: 48px;
}

@media screen and (max-width: 991.8px) {
    .ssc-scorecard-box .ssc-search-box .search-box {
        min-width: 250px;
    }
}

.ssc-scorecard-box .ssc-search-box .search-box img {
    width: 16px;
    height: 16px;
    top: -1px;
    position: relative;
}

.ssc-scorecard-box .ssc-search-box .search-box ::placeholder {
    color: var(--col-C5D2E7);
}

.grade-dropdown-custom .custom-btn {
    height: 48px;
    width: 107px;
    padding: 0 16px;
    gap: 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    border: 1px solid var(--col-br-light);
    border-radius: 4px;
}

.grade-dropdown-custom .custom-btn::after {
    display: none;
}

.grade-dropdown-custom .custom-drop-content {
    min-width: 107px;
    width: 107px;
    max-width: 140px;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid var(--col-br-light);
    border-top: 1px solid transparent;
    padding: 5px 16px;
}

.grade-dropdown-custom .custom-drop-content .dropdown-item {
    gap: 8px;
    background-color: var(--col-white);
    padding: 0px 8px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.grade-dropdown-custom .custom-drop-content .dropdown-item .dot-circle {
    margin-top: 2px;
    width: 10px;
    height: 10px;
    max-width: 10px;
    max-height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 100%;
}

.grade-dropdown-custom .custom-drop-content .dropdown-item .dot-circle.red {
    background-color: var(--col-error);
}

.grade-dropdown-custom .custom-drop-content .dropdown-item .dot-circle.yellow {
    background-color: var(--col-warning);
}

.grade-dropdown-custom .custom-drop-content .dropdown-item .dot-circle.green {
    background-color: var(--col-009262);
}

.grade-dropdown-custom .custom-drop-content .dropdown-item:hover {
    background-color: var(--col-br-dark);
}

.grade-dropdown-custom .custom-drop-content .dropdown-item .text-txt span {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-4B5564);
}

.ssc-scorecard-form label {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-secondary);
    margin-bottom: 8px;
}

.ssc-scorecard-form textarea::placeholder {
    color: var(--col-C5D2E7);
}

/* ssc-access-control-main */
.ssc-access-control-main .button-container {
    gap: 8px;
}

.ssc-access-control-main .para {
    font-size: var(--font-24);
    font-weight: var(--f-w-500);
}

.selectRole-button-container {
    gap: 8px;
}

.select-role-link {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    text-decoration: none;
    color: var(--col-btn-primary);
}

.custom-dopdown-access-control {
    width: 200px;
    border: 1px solid var(--col-br-dark);
    border-radius: 4px;
}

.custom-dopdown-access-control button {
    border: none;
    border-bottom: 1px solid var(--col-br-dark);
}

.customer-primary-edit .wrapper .content .options {
    height: 80px;
}

.sc-dropdown-custom-width {
    width: 140px !important;
}

.show-grade-color-sc .dot-circle {
    margin-top: 2px;
    width: 10px;
    height: 10px;
    max-width: 10px;
    max-height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 100%;
}

.show-grade-color-sc .dot-circle.yellow {
    background-color: var(--col-warning);
}

.show-grade-color-sc .dot-circle.red {
    background-color: var(--col-error);
}

.show-grade-color-sc .dot-circle.green {
    background-color: var(--col-009262)
}

.form-select:focus,
.form-select:focus-within {
    border-color: var(--col-btn-primary);
}

#impersonate-icon {
    border: 1px solid #8993A4;
    color: #8993A4;

    &:hover {
        color: var(--col-btn-primary-hover);
        cursor: pointer;
        border-color: var(--col-btn-primary-hover);
    }

    &:active {
        transform: scale(1.07);
    }
}

#action-btn {
    border: 1px solid #8993A4 !important;
    color: #8993A4;
    box-shadow: var(--shadow);
    border-radius: 100%;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;

    &:hover {
        color: var(--col-btn-primary-hover);
        cursor: pointer;
        border-color: var(--col-btn-primary-hover);
        box-shadow: var(--hov-shadow);
    }

    &:active {
        transform: scale(1.05);
    }
}

#action-btn-green {
    border: 1px solid #8993A4 !important;
    background-color: var(--col-btn-primary) !important;
    color: var(--col-white);
    box-shadow: var(--shadow);
    border-radius: 100%;
    width: 28px !important;
    height: 28px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;

    &:hover {
        color: var(--col-btn-primary-hover);
        cursor: pointer;
        border-color: var(--col-btn-primary-hover);
        box-shadow: var(--hov-shadow);
    }

    &:active {
        transform: scale(1.05);
    }
}

#exit-impersonate-icon {
    border: 1px solid #8993A4;
    color: #8993A4;
    position: relative;

    &:hover {
        color: var(--col-FF004D);
        cursor: pointer;
        border-color: var(--col-FF004D);
    }

    &:active {
        transform: scale(1.07);
    }
}

#impersonated-banner {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--col-FFC670);
    width: 100dvw;
    z-index: 50;
}

.circleSpinner-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.animate-spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.ssc-shadow {
    box-shadow: var(--shadow) !important;

    &:hover {
        box-shadow: var(--hov-shadow) !important;
    }
}

.main-tooltip {
    .tooltip-inner {
        padding: 0;
        margin: 0;
        max-width: 320px;
        text-align: left;
        box-shadow: var(--shadow);
    }

    .list-group-item {
        padding: 0.5rem;
    }

    .tooltip-arrow {
        &::before {
            border-bottom-color: #A9A9A9;
        }
    }
}


.grade-circle {
    border-radius: 100%;
    height: 12px;
    width: 12px;
    min-width: 12px;
    margin-left: 4px;
}

.red-circle {
    background-color: var(--col-error);
}

.yellow-circle {
    background-color: var(--col-warning);
}

.green-circle {
    background-color: var(--col-success);
}

.grey-circle {
    background-color: #616161;
}

.dashboard-box {
    border: 1px solid var(--col-br-dark);
    box-shadow: var(--shadow);
    border-radius: 8px;
    padding: 1rem;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &:hover {
        box-shadow: var(--hov-shadow) !important;
    }
}

.dashboard-box-title {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    padding: 0rem 1rem;
}

.divider {
    height: 1px;
    background-color: var(--col-br-dark);
}

.custom-search-box {
    position: relative;
    width: 100%;
    padding: 8px;
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 4px;
    gap: 8px;
    background-color: var(--col-white);

    .search-area {
        color: var(--col-body);
        line-height: 20px;
        font-size: var(--font-13);
        font-weight: var(--f-w-400);
        letter-spacing: 0.005em;

        &::placeholder {
            font-size: var(--font-13);
            font-weight: var(--f-w-400);
            line-height: 20px;
            letter-spacing: 0.005em;
            color: var(--col-body);
        }

        &:focus-visible,
        &:focus {
            outline: none !important;
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 36px;
    transform: scale(0.6);
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--col-btn-primary);

    &:hover {
        background-color: var(--col-btn-primary-hover);
    }
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.disabled-hover {
    &:disabled {
        cursor: not-allowed !important;
    }
}

.btn-link {
    color: var(--col-btn-primary) !important;
}

input:disabled,
select:disabled,
textarea:disabled {
    cursor: not-allowed !important;
}

.table-footer-btn {
    background-color: var(--col-btn-primary) !important;
    border-color: var(--col-btn-primary) !important;
    width: 100px;
    height: 42px;
    font-weight: 500;
    font-size: 17px;

    &:hover {
        background-color: var(--col-btn-primary-hover) !important;
        border-color: var(--col-btn-primary-hover) !important;
    }
}

.table-footer-btn:disabled {
    cursor: not-allowed !important;
    background-color: gray !important;
    border-color: gray !important;
}

.scroll-manage-hiring-clients {
    max-height: calc(100dvh - 400px);
    overflow-y: auto;
    overflow-x: auto;
    scrollbar-gutter: stable;

    table {
        thead {
            position: sticky;
            top: 0;
        }
    }
}

.rotate-svg-90 {
    transform: rotate(90deg) !important;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    transition: rotate 0.3s ease-in-out;
}

.dd-bg-hover {
    :hover {
        background-color: var(--col-E4FBFA) !important;
    }
}

.ymp-container {
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: var(--col-white);
    padding: 10px;

}

.ymp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.ymp-nav-button {
    border: none;
    background: none;
    font-size: 16px;
    cursor: pointer;
    color: var(--col-primary);
}

.ymp-year-display {
    font-weight: bold;
    font-size: 16px;
    color: var(--col-primary);
}

.ymp-month-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    color: var(--col-primary);
}

.ymp-month-button {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    cursor: pointer;
    text-align: center;
    font-size: 14px;
    background-color: var(--col-white) !important;
    color: var(--col-primary) !important;
    transition: background 0.3s;
}

.ymp-month-button:hover {
    background-color: var(--col-btn-primary-hover) !important;
    color: #fff !important;
    /* Example hover color */
}

.ymp-month-button.selected {
    background-color: var(--col-btn-primary-hover) !important;
    color: #fff !important;
}

.sidebar-ellipses {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.platform-tab {
    min-width: 240px;
}

@media (max-width : 768px) {

    .ssc-header .ssc-header-right {
        gap: 12px;
    }

    .platform-tab {
        min-width: 100%;
    }

    .modal-header {
        height: 50px !important;
    }

    .modal-title {
        font-size: 16px;
    }

    main {
        height: calc(100dvh - 50px) !important;

    }

    main.isImpersonated {
        height: calc(100dvh - 70px) !important;
    }
}

.button-green-filled {
    width: auto !important;
    height: 40px;
    border-radius: 4px !important;
    font-size: var(--font-16) !important;
    font-weight: var(--f-w-600) !important;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin: 16px 0;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
    }
}

.namebox-email {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

@media (max-width : 575px) {
    .ssc-logo {
        display: none;
    }

    .ssc-header {
        height: 48px;
    }

    .ssc-search-box .search-box {
        height: 40px !important;
    }

    .react-select__control {
        height: 36px !important;
    }

    .ssc-select__control {
        min-height: 36px !important;
    }

    .ssc-sliderbar {
        top: 50px !important;
        height: calc(100vh - 50px) !important;
    }

    .ssc-sliderbar.isImpersonated {
        top: 74px !important;
        height: calc(100vh - 74px) !important;
    }

    .ssc-primary-green-btn,
    .form-white-button {
        padding: 8px !important;
        font-size: var(--font-15) !important;
    }

    .button-green-filled,
    .form-white-button {
        height: 32px !important;
        border-radius: 4px !important;
        font-size: var(--font-15) !important;
        margin: 8px 0 !important;

        &:disabled {
            cursor: not-allowed !important;
        }
    }

    input {
        height: 36px !important;
        padding-left: 4px;
        padding-right: 22px;
    }

    .custom-search-box {
        height: 40px !important;
    }
}

@media (max-width : 480px) {
    .customized-dropdown .custom-btn {
        max-width: 120px;
    }
}

.header-popoverMenu-btn {
    background-color: var(--col-white);
    color: var(--col-text);
    border: none;
    cursor: pointer;
    padding: 4px;

    &:active {
        background-color: var(--col-E4FBFA) !important;
    }

    &:hover {
        color: var(--col-dark) !important;
        background-color: var(--col-E4FBFA) !important;
    }
}

.header-popoverMenu {
    display: flex !important;
    min-width: max-content;
    width: 0 !important;
    background-color: var(--col-white);
    inset: 5px auto auto -60px !important;
    padding-inline: 6px;

    >.dropdown-item {
        padding: 4px 10px;
    }

    .dropdown-item:hover {
        background-color: var(--col-white) !important;
    }

    .tab-open-area .content-area .left-side {
        padding: 0px;
    }

    .tab-open-area .content-area .right-side {
        height: calc(100dvh - 120px) !important;
    }
}

@media (max-width : 480px) {
    .select-box-new {
        margin: 0px !important;
    }
}

@media (max-width : 800px) {
    .tab-open-area {
        width: 90% !important;
        overflow: auto;
    }

    .tab-open-area .close-btn {
        left: 0px !important;
        top: 12px !important;
    }
}

.edit-profile-form-area {
    height: calc(100dvh - 240px);
    overflow-y: auto;
    overflow-x: hidden;
}

.sticky-th-left {
    position: sticky;
    left: -5px;
    z-index: 88;
    background-color: inherit;
    opacity: 0.95;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.sticky-td-left {
    position: sticky;
    left: -5px;
    z-index: 88;
    background-color: inherit;
    opacity: 0.95;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.sticky-th-right {
    position: sticky;
    right: -5px;
    z-index: 89;
    background-color: inherit;
    opacity: 0.95;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.sticky-td-right {
    position: sticky;
    right: -5px;
    z-index: 88;
    background-color: inherit;
    opacity: 0.95;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    -moz-box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.left-pos-30 {
    left: 30px;
}

.left-neg-16 {
    left: -1rem;
}

.unhide-hc-btn {
    position: absolute;
    top: 5rem;
    right: 2rem;
}

@media (max-width : 767px) {
    .unhide-hc-btn {
        top: 20px;
    }

    .doc-request-box {
        padding: 16px 4px !important;
        margin-bottom: 16px;
    }
}

.min-w-8 {
    min-width: 8rem !important;
}

.min-w-9 {
    min-width: 9rem !important;
}

.min-w-10 {
    min-width: 10rem !important;
}

.min-w-12 {
    min-width: 12rem !important;
}

.min-w-15 {
    min-width: 15rem !important;
}

@media (max-width : 586px) {

    .add-new-hc-block,
    .add-new-user-block {
        position: sticky;
        left: 0px;
    }
}

.ssc-select__placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

#ssc-textarea::placeholder {
    font-size: 14px;
    padding-top: 0.25rem;
}

.cursor-na {
    cursor: not-allowed !important;
}

.mw-10 {
    max-width: 10rem;
}

.mw-11 {
    max-width: 11rem;
}

.mw-12 {
    max-width: 12rem;
}

.mw-13 {
    max-width: 13rem;
}

.mw-14 {
    max-width: 14rem;
}

.mw-15 {
    max-width: 15rem;
}

.mw-16 {
    max-width: 16rem;
}

.mw-17 {
    max-width: 17rem;
}

.mw-18 {
    max-width: 18rem;
}

.mw-19 {
    max-width: 19rem;
}

.mw-20 {
    max-width: 20rem;
}

.mw-21 {
    max-width: 21rem;
}

.mw-22 {
    max-width: 22rem;
}

.mw-23 {
    max-width: 23rem;
}

.mw-24 {
    max-width: 24rem;
}

.mw-25 {
    max-width: 25rem;
}

.draggable-row {
    background-color: #f0f8ff !important;
    box-shadow: var(--hov-shadow) !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
    opacity: 0.9;
}

.draggable-row-data {
    background-color: #f0f8ff !important;
    width: 100% !important;
    border-bottom: none !important;
}

.mouse-pointer {
    cursor: pointer;
}

