:root {
    --col-primary: #254960;
    --col-secondary: #3f647d;
    --col-body: #738da6;
    --col-label: #e2f1f7;
    --col-br-light: #e9f0fb;
    --col-br-dark: #e2f1f7;
    --col-white: #ffffff;
    --col-E4FBFA: #e4fbfa;
    --col-tra: transparent;
    --col-table-head: #f4fafd;
    --col-4B5564: #4b5564;
    --col-C5D2E7: #c5d2e7;
    --col-E9E9E9: #e9e9e9;
    --col-E02B1D: #e02b1d;
    --col-FFF1F0: #fff1f0;
    --col-5DEADE: #5deade;
    --col-FFB240: #ffb240;
    --col-FFF8EC: #fff8ec;
    --col-32C997: #32c997;
    --col-F1FBF8: #f1fbf8;
    --col-009262: #009262;
    --col-1145C8: #1145c8;
    --col-F0857D: #f0857d;
    --col-FF004D: #ff004d;
    --col-FFC670: #ffc670;
    --col-FDF5ED: #fdf5ed;
    --col-84DFC1: #84dfc1;
    --col-FF5A4F: #ff5a4f;
    --col-FFCCDB: #ffccdb;
    --col-EFFAFF: #effaff;
    --col-2D343F: #2d343f;
    --col-E9E5E5: #e9e5e5;
    --col-hov-c5fdfa: #c5fdfa;
    --col-hov-f9d9ba: #f9d9ba;
    --col-hov-f7d2cf: #f7d2cf;
    --col-btn-primary: #17b1a4;
    --col-btn-primary-hover: #128c82;
    --col-warning: #ffa826;
    --col-error: #e01507;
    --col-success: #1b6e53;
    --font-family: 'Rubik', sans-serif;
    --font-10: 10px;
    --font-11: 11px;
    --f-w-400: 400;
    --font-12: 12px;
    --font-13: 13px;
    --font-14: 14px;
    --f-w-600: 600;
    --font-16: 16px;
    --f-w-500: 500;
    --font-18: 18px;
    --font-24: 24px;
    --font-32: 32px;
    --font-92-81: 92.81px;
    --shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    --hov-shadow: 0 4px 8px -0px rgba(57, 67, 87, .3);
}

.csm-image {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    margin-right: 10px;
}

.spinner {
    color: #32c997;
    width: 100px;
    height: 100px;
}

.loader {
    z-index: 999999;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.85);
    height: 100dvh;
    width: 100dvw;
}

.error-message {
    padding: 0 3px;
    font-size: var(--font-12);
    font-weight: var(--f-w-500);
    color: var(--col-error);
    margin: 5px 0;
}

.ssc-primary-green-btn:disabled {
    background-color: var(--col-label);
    cursor: default;
}

/* Will be deleted after it is added in main.css*/
.form-footer h5 {
    margin-top: 5px;
}

.login-page-forgot-reset-link {
    width: 90%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.img-user-profile {
    vertical-align: middle;
}

.ssc-header .ssc-header-right .select-box-new {
    border: none;
    margin: 0px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-4B5564);
}

.ssc-secondary-red-btn {
    height: 23px;
    width: 53px;
    padding: 10px 16px;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--col-error);
    border-radius: 4px;
    background-color: var(--col-white);
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var(--col-error);
    transition: all 0.3s ease-in-out;

    p {
        margin-bottom: 0px;
    }

    &:hover {
        background-color: var(--col-hov-f7d2cf);
        border: 1px solid var(--col-error) !important;
        color: var(--col-error) !important;
    }
}

.approve-div-btn .ssc-secondary-white-btn {
    height: 23px;
    width: 65px;
}

.approve-div-btn {
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    top: 52px;
}

.notification-mark-all-as-read.disabled {
    color: var(--col-body) !important;
    cursor: not-allowed;
    border: none;
    background: none;
}

.notification-title .tabs-filters .filter-btn:disabled {
    color: var(--col-body) !important;
    cursor: not-allowed;
}

.remove-file-btn {
    top: 5px;
    left: 5px;
    z-index: 100;
}

.file-upload {
    gap: 10px;
    flex-wrap: wrap;
}

.notification-mark-all-as-read.button {
    border: none;
    background: none;
}

.notification-title .tabs-filters .filter-btn:disabled {
    color: var(--col-body) !important;
    cursor: not-allowed;
}

.priority {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-12);
    font-weight: var(--f-w-400);
    line-height: 18px;
    letter-spacing: 0.01em;
    color: var(--col-primary);
    border-radius: 1000px;
    padding: 0px 8px;
    height: 20px;
}

.priority-set .high-priority {
    width: 42px;
    border: 1px solid var(--col-E02B1D);
    background-color: var(--col-FFF1F0);
}

.priority-set .critical-priority {
    width: 65px;
    border: 1px solid var(--col-5DEADE);
    background-color: var(--col-E4FBFA);
}

.priority-set .medium-priority {
    width: 65px;
    border: 1px solid var(--col-FFB240);
    background-color: var(--col-FFF8EC);
}

.priority-set .low-priority {
    width: 42px;
    border: 1px solid var(--col-32C997);
    background-color: var(--col-F1FBF8);
}

.status-set .requested-status {
    border: 1px solid var(--col-C5D2E7);
    background-color: var(--col-F1FBF8);
}

.dot-new {
    border-radius: 100px;
    width: 10px;
    height: 10px;
    margin-right: 10px;
}

.red-dot {
    background-color: var(--col-error);
}

.green-dot {
    background-color: var(--col-009262);
}

.blue-dot {
    background-color: var(--col-1145C8);
}

.yellow-dot {
    background-color: var(--col-warning);
}

.document-fix-width {
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.truncate-text {
    max-width: 120px;
    /* Adjust the max-width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    /* Ensures ellipsis works correctly */
}

.file-extension {
    margin-left: 5px;
    /* Adjust spacing as needed */
    color: gray;
    /* Optionally style the extension */
    max-width: 120px;
    /* Adjust the max-width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    /* Ensures ellipsis works correctly */
}

.file-extension {
    margin-left: 5px;
    /* Adjust spacing as needed */
    color: gray;
    /* Optionally style the extension */
}

.document-modal-title {
    font-size: 32px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: -0.005em;
    text-align: center;
    color: var(--col-primary);
    margin-bottom: 48px;
}

.modal-btn.custom-button button {
    white-space: nowrap;
}

.add-btn-area {
    max-height: 40px;
    max-width: 40px;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.upload-file {
    width: auto !important;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--col-white);
    height: 36px;
    margin-top: 25px;
}

.background-color-transparent {
    background-color: var(--col-tra);
}

.white-space-nowrap {
    white-space: nowrap;
}

/* .scroll{
  max-height: 200px;
  overflow-y:auto;
  overflow-x:hidden;
} */
/* For session popup*/
.popup-overlay {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    .popup {
        background-color: var(--col-white);
        border-radius: 8px;
        border: 1px solid var(--col-br-dark);
        padding: 20px;

        .popup-header {
            display: flex;
            justify-content: flex-end;
        }

        .popup-content {
            margin-bottom: 20px;
            font-size: var(--font-24);
            font-weight: var(--f-w-500);
        }

        .popup-footer {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .ssc-secondary-white-btn {
                font-size: var(--font-18);
                font-weight: var(--f-w-600);
                border-radius: 8px;
            }
        }
    }
}

.notification-list .notification-text {
    cursor: pointer;
}

.notification-list .notification-text.new-notification {
    background-color: var(--col-E4FBFA);
}

.notification-list .notification-text.page-text {
    margin-bottom: 8px;
}

.notification-list .notification-text.page-text:last-child {
    margin-bottom: 0px;
}

.notification-list .notification-text .title {
    font-size: var(--font-16);
    font-weight: var(--f-w-500);
    line-height: 1;
    letter-spacing: 0.01em;
    color: var();
    margin-bottom: 12px;
}

.notification-list .notification-text .text {
    font-size: var(--font-13);
    font-weight: var(--f-w-400);
    line-height: 16px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
}

.notification-list .notification-text .notify-mark-dot {
    width: 12px;
    height: 12px;
}

.notification-list .notification-text .notify-mark-dot .dot {
    background-color: var(--col-btn-primary);
    border-radius: 100%;
    width: 12px;
    height: 12px;
}

.blink-notification {
    animation: blinkAnimation 0.4s infinite alternate;
}

@keyframes blinkAnimation {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: var(--col-E4FBFA);
    }
}

.selected-table-row {
    background-color: var(--col-E4FBFA) !important;
}

.notes-textarea {
    border: 1px solid var(--col-br-dark);
    border-radius: 4px;
}

.custom-table-height-sc {
    height: calc(100dvh - 330px) !important;
}

.timing {
    font-size: var(--font-12) !important;
    font-weight: var(--f-w-400);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-body);
}

.save-notes-button {
    margin-left: 10px;
    border-radius: 8px;
    border: 1px solid var(--col-btn-primary);
    margin-top: -8px;
    color: var(--col-dark);
}

.close-btn {
    border: 2px solid var(--col-E4FBFA);
}

.ssc-scorecard-box .grade-dropdown-custom .custom-drop-content {
    min-width: 125px;
    width: 125px;
    max-width: 140px;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid var(--col-br-light);
    border-top: 1px solid transparent;
    padding: 5px 16px;
}

.ssc-scorecard-box .grade-dropdown-custom .custom-btn {
    height: 48px;
    width: 125px;
    padding: 0 16px;
    gap: 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    border: 1px solid var(--col-br-light);
    border-radius: 4px;
}

.custom-modal-size {
    min-width: 1100px;
    left: 50%;
    transform: translate(-50%, 0);
}

@media screen and (max-width: 1200px) {
    .custom-modal-size {
        min-width: 900px;
    }
}

@media screen and (max-width: 991.8px) {
    .custom-modal-size {
        min-width: 700px;
    }
}

.custom-modal-size #proxy-renderer {
    overflow: hidden !important;
}

.file-viewer-height-75vh {
    height: 85vh;
}

.file-viewer-doc-height {
    height: calc(100% - 50px) !important;
}

.file-viewer-file-name-header {
    font-size: 20px;
    min-height: 50px;
    font-weight: bold;
}

.external-viewer-url {
    width: 100%;
    height: calc(100% - 50px);
}

.custom-modal-doc-cato .ssc-primary-green-btn.add-upload-file-btn {
    width: auto !important;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--col-white);
    border-radius: 100%;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 0px;
}

.custom-wid-customer {
    height: 410px;
}

.edit-cross-btn {
    position: absolute;
    right: 0;
    border: none;
    outline: none;
    box-shadow: none;
    top: 1px;
}

.form-subTitle {
    font-size: 22px;
    font-weight: var(--f-w-400);
}

.type-select-panel {
    height: 48px;
    padding: 0 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
}

.hiring-client-box:nth-child(odd) {
    background-color: #f0f3f5;
}

.manage-user-btn .w-auto.ssc-primary-green-btn {
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 15.73px;
    letter-spacing: 0.01em;
    height: 36px;
    border-radius: 4px;
}

.select-option-csm {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
}

.new-customer-input::placeholder {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-C5D2E7);
}

.text-color {
    color: black;
}

.margin-left-8 {
    margin-left: 8px;
}

.text-special {
    font-family: var(--font-family) !important;
}

.badge {
    border-radius: 100%;
    font-size: x-large;
    width: 60px;
    height: 60px;
}

.placeholder-color {
    color: var(--col-4B5564);
}

.text-color {
    color: black;
}

.text-special {
    font-family: var(--font-family) !important;
}

.badge {
    border-radius: 100%;
    font-size: x-large;
}

.profile-box .profile-img .img-edit {
    bottom: 0 !important;
    right: -7px !important;
    border-radius: 100%;
}

.header-image {
    border-radius: 100%;
}

.edit-box-customer {
    width: 100%;
    height: 900px;
}

.plus-icon-edit {
    width: auto !important;
    font-size: var(--font-13);
    font-weight: var(--f-w-600);
    line-height: 16px;
    letter-spacing: 0.01em;
    color: var(--col-white);
    border-radius: 100%;
    max-height: 40px;
    max-width: 40px;
    min-height: 40px;
    min-width: 40px;
    padding: 0px;
    margin-top: 18px;
    column-gap: 10px;
}

.ssc-warning-btn {
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-18);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    border: 1px solid var(--col-warning);
    border-radius: 8px;
    color: var(--col-white);
    transition: all 0.3s ease-in-out;
}

.ssc-warning-btn:hover {
    background-color: var(--col-warning-hover);
}

.customer-edit-btn {
    border: 1px solid rgb(167, 165, 165);
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    position: absolute;
}

.user-create-form {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 0.5rem;
    margin-top: 0.75rem;
    margin-inline: 0.75rem;
}

.scroll-create-user {
    max-height: calc(100dvh - 440px);
    overflow-y: auto;
    padding-inline: 16px;
}

.notification-bar {
    position: fixed;
    top: 72px;
    left: 0;
    width: 100%;
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition:
        top 0.3s ease,
        transform 0.3s ease;
}

.notification-bar.hidden {
    top: -72px;
    transform: translateY(0);
}

.notification-bar .btn-close {
    cursor: pointer;
    font-size: 13px;
    border: none !important;
}

.notification-bar .message {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.header-position-relative {
    position: relative;
    z-index: 999;
}

.tabs-set .tabs-row {
    border-bottom: none !important;
    width: 100%;
}

.field-disabled {
    background-color: var(--bs-secondary-bg) !important;
}

.remove-img {
    position: absolute;
    right: -1px;
    top: -5px;
    border-radius: 50% !important;
    height: 23px;
    width: 23px;
}

.ssc-secondary-white-btn:disabled {
    background-color: var(--col-label);
    cursor: not-allowed;
}

.label-color {
    color: var(--col-secondary) !important;
}

.min-width-480 {
    min-width: 480px !important;
}

@media (max-width : 700px) {
    .min-width-480 {
        min-width: 360px !important;
    }
}

.note {
    font-size: var(--font-13);
    color: var(--col-body);
}

.dot-circle {
    margin-top: 2px;
    width: 10px;
    height: 10px;
    max-width: 10px;
    max-height: 10px;
    min-width: 10px;
    min-height: 10px;
    border-radius: 100%;
}

.dot-circle.yellow {
    background-color: var(--col-warning);
}

.dot-circle.red {
    background-color: var(--col-error);
}

.dot-circle.green {
    background-color: var(--col-009262);
}

.modal-text {
    font-size: 18px !important;
    line-height: 30px !important;
}

.btn-grey-border {
    border: 1px solid hsl(0, 0%, 80%) !important;
}

.grade-btn-active {
    background-color: var(--col-label);
    border: 1px solid var(--col-btn-primary) !important;
    color: var(--col-btn-primary) !important;
    box-shadow: var(--hov-shadow);
}

.dot-set-width-40 {
    width: 40px !important;
}

.empty-dot {
    border-radius: 100%;
    height: 10px;
    width: 10px;
    min-width: 10px;
    border: 1px solid var(--col-secondary);
}

/* Information icon*/
.info-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #17a2b8;
    color: white;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    margin-left: 8px;
}

/* Initially hide the scrollbar */
::-webkit-scrollbar {
    overflow-y: auto !important;
}

/* Show the scrollbar only when scrolling */
.scrolling::-webkit-scrollbar {
    display: block !important;
}

/* Hide the scrollbar when not scrolling */
.not-scrolling::-webkit-scrollbar {
    display: none !important;
}

.profile-button:disabled {
    background-color: var(--col-label) !important;
    cursor: not-allowed;
}

input[type="search"]::-webkit-search-cancel-button {
    cursor: pointer;
}

.edit-customer-heading {
    border: 1px solid var(--bs-border-color);
    color: var(--col-primary);
    padding: 10px 16px;
    border-radius: 4px;
    line-height: 1;
}

.csm-image-strech {
    aspect-ratio: 1/1 !important;
}

.edit-button-customer-tab {
    column-gap: 3px;
    margin-bottom: 16px;
}

.save-change-button {
    background-color: var(--col-btn-primary);
    border-radius: 4px;
    height: 40px;
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 0 16px;
    color: var(--col-white);
    transition: all 0.3s ease-in-out;
}

.platform-boundary {
    padding: 10px 8px !important;
}

.form-white-button {
    background-color: transparent;
    border: 1px solid var(--col-btn-primary) !important;
    border-radius: 4px;
    height: 40px;
    font-size: var(--font-16);
    font-weight: var(--f-w-600);
    line-height: 24px;
    letter-spacing: 0.01em;
    padding: 0 16px;
    color: var(--col-btn-primary);
    transition: all 0.3s ease-in-out;
}

.form-white-button:hover {
    background-color: var(--col-label);
    border: 1px solid var(--col-btn-primary) !important;
    color: var(--col-btn-primary) !important;
}

.scroll-manage-platform {
    max-height: calc(100dvh - 580px);
    overflow-y: auto;
    overflow-x: auto;

    table {
        thead {
            position: sticky;
            top: 0;
        }
    }
}

.notification-txt {
    max-height: 0;
    overflow: hidden;
    font-size: var(--font-13);
    transition: max-height 0.3s ease;
}

.notification-txt.notification-txt-expand {
    max-height: 20px;
}

.notification-drop-icon-spacing {
    width: 32px;
    height: 32px;
    transition: all 0.3s ease-in-out;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
}

.notification-drop-icon-spacing:hover {
    background-color: var(--col-E4FBFA);
}


/*----- UTILITY CLASSES -----*/

/*
* Please add other classes above this section
* Add only utility classes below
*/

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.border-radius-4px {
    border-radius: 4px !important;
}

.height-60px {
    height: 60px !important;
}

.z-index-9999 {
    z-index: 9999 !important;
}

.hollow-box {
    border-radius: 8px;
    border: 1px solid var(--col-br-dark);
}

.font-16px {
    font-size: 16px !important;
}

.placeholder-color {
    color: var(--col-4B5564);
}

.border-none {
    border: none !important;
}

.cancel-box {
    border-radius: 100%;
    font-size: 18px;
    font-weight: 400;
    background-color: var(--col-F0857D);
}

.cancel-box:hover {
    background-color: var(--col-FF5A4F);
}

.height-40px {
    height: 40px !important;
}

.customized-dropdown .custom-btn {
    height: 48px;
    padding: 0 16px;
    gap: 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-secondary);
    border: 1px solid var(--col-br-light);
    border-radius: 4px;
}

.customized-dropdown .custom-btn::after {
    display: none;
}

.customized-dropdown ul {
    min-width: 0px;
    width: 100%;
    border-radius: 0px 0px 4px 4px;
    border: 1px solid var(--col-br-light);
    border-top: 1px solid transparent;
    padding: 5px 0px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-4B5564);
}

.customized-dropdown .text-txt {
    width: 100%;
}

.customized-dropdown .text-txt span {
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.top-12px {
    top: 12px !important;
}

.edit-box-row {
    width: 80px;
    max-width: 80px;
    min-width: 80px;
}

/*
* Please add other classes above this section
* Add only utility classes in this section
*/

/* Added z index to tooltip in header */
.bs-tooltip-bottom {
    z-index: 9999 !important;
}

/* Grey platform grade */
.grey-dot {
    background-color: #616161;
}

.customer-dashboard-view .top-right .grey-label {
    border: 1px solid #7C8A7C;
    background-color: var(--col-E9E5E5);
    transition: all ease-in-out 0.3s;
}

.customer-dashboard-view .top-right .grey-label:hover {
    background-color: #AFADAD;
}

.margin-top-7 {
    margin-top: -7px;
}

.height-fit-content {
    height: fit-content !important;
}

.font-weight-normal {
    font-weight: normal !important;
}

.add-grade-hc-text {
    padding: 12px 16px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 8px;
}

.add-grade-hc-text:focus {
    border: 2px solid var(--col-btn-primary);
    box-shadow: none;
}

.add-grade-hc-text:focus-visible {
    outline: none;
}

.add-grade-hc-text::placeholder {
    color: #C6D3E8;
}

.new-table-responsiveness .ssc-table-area.draggable {
    height: calc(100dvh - 50vh) !important;
}

@media (max-width: 768px) {
    .card-title-area {
        flex-direction: column;
        align-items: flex-start;
    }

    .card-title-area h5 {
        margin-bottom: 0.5rem;
    }

    .custom-dropdown {
        width: 100%;
    }
}

@media (max-width: 1200px) {

    .card-title-area {
        flex-direction: column;
        align-items: center !important;
        justify-content: center !important;
    }

    .card-title-area h5 {
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .custom-dropdown {
        width: 100%;
    }
}

.form-button:disabled {
    background-color: var(--col-label);
    cursor: not-allowed;
}

.max-width-40-percent {
    max-width: 40% !important;
    min-width: 120px !important;
}

.form-control {
    padding: 0px 16px;
    height: 48px;
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 20px;
    letter-spacing: 0.005em;
    color: var(--col-primary);
}

.form-control:focus {
    box-shadow: none;
    border: 2px solid var(--col-btn-primary);
}

.form-control::placeholder {
    font-size: var(--font-13);
    font-weight: var(--f-w-500);
    line-height: 19.5px;
    letter-spacing: 0.005em;
    color: var(--col-C5D2E7);
}

/*--------END OF FILE --------*/